import * as React from 'react';
import {
	AutocompleteInput,
	Edit,
	EditProps,
	ReferenceInput,
	required,
	SimpleForm,
	TextInput,
	Toolbar,
	SaveButton,
	DeleteButton,
	usePermissions,
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { LocationTitle } from './LocationTitle';
import { EUserType } from '../enums';
import { MultipleImagesInput } from '../components/MultipleImagesInput';
import { formatPhoneNumber } from "../util/Utils";

export const LocationEdit = (props: EditProps): React.ReactElement => {
	const { permissions } = usePermissions();

	const CustomToolbar = () => (
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<SaveButton />
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <DeleteButton mutationMode="pessimistic" />
			}
		</Toolbar>
	);

	return (
		<Edit {...props} mutationMode="pessimistic" title={<LocationTitle/>}>
			<SimpleForm sx={{ '& > *': { width: '100%' } }} toolbar={<CustomToolbar/>}>
				<TextInput source="name" label="BUSINESS_NAME" validate={[required()]}/>
				<TextInput source="contactName" label="CONTACT_NAME" validate={[required()]}/>
				<TextInput
					format={formatPhoneNumber} parse={formatPhoneNumber}
					sx={{ direction: 'ltr !important' }}
					type="tel" source="contactPhone"
					label="CONTACT_PHONE" validate={[required()]}/>
				<TextInput type="email" source="contactEmail" label="CONTACT_EMAIL"/>
				<TextInput source="contactNameB" label="CONTACT_NAME_B"/>
				<TextInput
					format={formatPhoneNumber} parse={formatPhoneNumber}
					sx={{ direction: 'ltr !important' }}
					type="tel" source="contactPhoneB" label="CONTACT_PHONE_B"/>
				<TextInput type="email" source="contactEmailB" label="CONTACT_EMAIL_B"/>
				<GoogleAddressInput source="address" label="ADDRESS" validate={[required()]}/>
				{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                    <ReferenceInput
                        label="BUSINESS"
                        source="businessId" reference="businesses"
                        alwaysOn
                        validate={[required()]}
                    >
                        <AutocompleteInput label="BUSINESS" optionText="name" validate={[required()]}/>
                    </ReferenceInput>
				}
				<TextInput source="address.directions" label="DIRECTIONS"/>
				<MultipleImagesInput source="address.directionsImagesUrls" label="DIRECTIONS_IMAGES"/>
				<TextInput source="remarks" label="REMARKS"/>
			</SimpleForm>
		</Edit>
	);
};
