import * as React from 'react';
import { useRecordContext, useTranslate, } from 'react-admin';
import { Delivery } from './Delivery';

export const DeliveryTitle = () => {
	const translator = useTranslate();
	const delivery: Delivery = useRecordContext();

	return <span>{translator('DELIVERY')} {delivery ? ` - #${delivery.id}` : ''}</span>;
};

