import React from 'react';
import { Login } from 'react-admin';
import { OtpForm } from './OtpForm';

export const OtpLoginPage = () => (
	<Login
		className="CustomLoginPage"
		title={'AnySpot | פאנל ניהול'}
		backgroundImage="assets/login-background.jpg"
	>
		<OtpForm/>
	</Login>
);
