import * as React from 'react';
import { useState } from 'react';
import {
	ArrayInput,
	AutocompleteInput,
	BooleanInput,
	DateInput,
	FormDataConsumer,
	Labeled,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleFormIterator,
	TextField,
	TextInput,
	useDataProvider,
	useNotify,
	usePermissions,
	useRefresh,
	useTranslate,
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import {
	EApprovalStatus,
	EDeliveryFailReason,
	EDeliveryPackageSize,
	EDeliveryPackageType,
	EDeliveryStatus,
	EDeliveryType,
	EUserType,
	EWeekday
} from '../enums';
import WeekdaysInput from '../components/WeekdaysInput';
import { TimeInput } from '../components/TimeInput';
import { SingleImageInput } from '../components/SingleImageInput';
import { useFormContext } from 'react-hook-form';
import { Business } from '../business';
import { Location } from '../location';
import { UploadFile } from '../components/UploadFile';
import { TabsInput } from '../components/TabsInput';
import './DeliveryForm.scss';
import { MultipleImagesInput } from '../components/MultipleImagesInput';

const styles = {
	nestedInput: {
		input: {
			boxShadow: 'inset 0 0 4px rgba(25, 118, 210, 0.5)',
			borderRadius: '.25rem'
		}
	}
};

export const DeliveryForm = (): React.ReactElement => {
	const translator = useTranslate();
	const form = useFormContext();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const [selectedLocation, setSelectedLocation] = useState<Location>();
	const { permissions } = usePermissions();
	const classes = styles;

	const handleBusinessChange = async (businessId) => {
		if (businessId) {
			try {
				const { data } = await dataProvider.getOne<Business>('businesses', { id: businessId });

				form.setValue('price', data.deliveryPrice);
				refresh();
			} catch (error) {
				notify('Error fetching delivery price', { type: 'error' });
			}
		} else {
		}
	};

	const handleLocationChange = async (locationId) => {
		if (locationId) {
			try {
				const { data } = await dataProvider.getOne<Location>('locations', { id: locationId });

				if (!data.address.city) {
					const splitAddress = data.address.addressStr.split(', ');
					if (splitAddress?.length > 1) {
						const lastWord = splitAddress[splitAddress.length - 1];
						const cityIndex = (lastWord === 'ישראל' || lastWord === 'Israel') ? splitAddress.length - 2 : splitAddress.length - 1;

						data.address.city = { name: splitAddress[cityIndex] };
					}
				}

				form.setValue('pickUpAddress', data.address);
				form.setValue('pickUpAddress.directions', data.address?.directions);
				form.setValue('pickUpAddress.directionsImagesUrls', data.address?.directionsImagesUrls);
				form.setValue('notes', data.remarks);
				setSelectedLocation(data);
			} catch (error) {
				notify('Error fetching delivery price', { type: 'error' });
			}
		} else {
			setSelectedLocation(undefined);
		}
	};

	return (
		<>
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <ReferenceInput
                    label="BUSINESS"
                    source="businessId" reference="businesses"
                    alwaysOn
                >
                    <AutocompleteInput onChange={handleBusinessChange} label="BUSINESS"
                                       optionText="name"/>
                </ReferenceInput>
			}
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <ReferenceInput
                    sort={{ field: '_updatedAt', order: 'ASC' }}
                    filter={{ 'employee.businessId': form.getValues('businessId') }}
                    label="CREATED_BY"
                    source="createdById" reference="users"
                >
                    <AutocompleteInput label="CREATED_BY" optionText="fullName" optionValue="id"/>
                </ReferenceInput>
			}
			<ReferenceInput
				label="LOCATION"
				source="locationId" reference="locations"
				filter={{ 'business': form.getValues('businessId') }}
				alwaysOn
			>
				<AutocompleteInput label="LOCATION" optionText="name" onChange={handleLocationChange}/>
			</ReferenceInput>
			{selectedLocation &&
                <div>
                    <h4 style={{ margin: 0 }}>{translator('LOCATION_CONTACT_DETAILS')}</h4>
					{!(selectedLocation.contactName ||
						selectedLocation.contactPhone ||
						selectedLocation.contactNameB ||
						selectedLocation.contactPhoneB) && <span style={{ color: 'red' }}>לא נמצאו פרטי התקשרות</span>}
					{(selectedLocation.contactName ||
						selectedLocation.contactPhone) && <p>
                        <TextField record={selectedLocation} source="contactName"/>&nbsp;<TextField
                        record={selectedLocation} source="contactPhone"/>
                    </p>}
					{(selectedLocation.contactNameB ||
						selectedLocation.contactPhoneB) && <p>
                        <TextField record={selectedLocation} source="contactNameB"/>&nbsp;<TextField
                        record={selectedLocation} source="contactPhoneB"/>
                    </p>}
                    <p>*לשינוי פרטי התקשרות יש לגשת לכרטיסיית הלקוח</p>
                </div>}
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <ReferenceInput
                    label="COURIER"
                    source="courierId" reference="couriers"
                    alwaysOn filter={{ 'eq_user.approvalStatus': EApprovalStatus.Approved }}
                >
                    <AutocompleteInput label="COURIER" optionText="user.fullName"/>
                </ReferenceInput>
			}
			<ArrayInput name="deliveryPackages" source="deliveryPackages" label="DELIVERY_PACKAGES"
			            validate={(value, values, props) => value?.length > 0 ? null : translator('MINIMUM_ONE_PACKAGE')}
			            defaultValue={[{
				            type: EDeliveryPackageType.Checks,
				            size: EDeliveryPackageSize.Small
			            }]}>
				<SimpleFormIterator inline sx={{
					padding: '.5rem',
					borderRadius: '.5rem',
					boxShadow: '0px 15px 20px #27313f0c',
					backgroundColor: 'white'
				}}>
					<TabsInput label="SIZE" source="size"
					           defaultValue={EDeliveryPackageSize.Small}
					           choices={Object.keys(EDeliveryPackageSize).map(key => ({
						           id: key,
						           name: EDeliveryPackageSize[key]
					           }))}/>
					<TabsInput source="type" label="TYPE" validate={[required()]}
					           defaultValue={EDeliveryPackageType.Checks}
					           choices={Object.keys(EDeliveryPackageType).map(key => ({
						           id: key,
						           name: EDeliveryPackageType[key]
					           }))}/>
					<FormDataConsumer>
						{({
							  formData, // The whole form data
							  scopedFormData, // The data for this item of the ArrayInput
							  getSource
						  }) => {
							if (scopedFormData) {
								if (scopedFormData.type === EDeliveryPackageType.Checks) {
									return <Labeled label={translator('CHECKS_DETAILS')}>
										<div style={{ display: 'flex', gap: '1rem' }}>
											<NumberInput sx={classes.nestedInput}
											             validate={required()}
											             source={getSource!('checksDetails.count')} label="COUNT"/>
											<NumberInput sx={classes.nestedInput}
											             source={getSource!('checksDetails.sum')} label="SUM"/>
											<TextInput sx={classes.nestedInput}
											           source={getSource!('checksDetails.months')}
											           label="CHECKS_FOR_MONTHS"/>
										</div>
									</Labeled>;
								} else if (scopedFormData.type === EDeliveryPackageType.Cash) {
									return <Labeled label={translator('CASH_DETAILS')}>
										<NumberInput source={getSource!('cashDetails.sum')} label="SUM"/>
									</Labeled>;
								}
							}
						}
						}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
			{[EUserType.Admin].includes(permissions) &&
                <TextInput source="price" label="PRICE"/>
			}
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <SelectInput source="status" label="STATUS" validate={[required()]}
                             defaultValue={EDeliveryStatus.New}
                             choices={Object.keys(EDeliveryStatus).map(key => ({
					             id: key,
					             name: EDeliveryStatus[key]
				             }))}/>
			}
			<TabsInput label="TYPE" source="type"
			           defaultValue={EDeliveryType.PickUp}
			           choices={Object.keys(EDeliveryType).map(key => ({
				           id: key,
				           name: `DELIVERY_TYPE.${EDeliveryType[key]}`
			           }))}/>
			<GoogleAddressInput source="pickUpAddress" label="PICK_UP_ADDRESS" isRequired validate={[required()]}/>
			<ReferenceInput
				label="PICK_UP_CITY"
				source="pickUpCityId" reference="cities"
				alwaysOn
			>
				<AutocompleteInput label="PICK_UP_CITY" optionText="name"/>
			</ReferenceInput>
			<div className="address-remark remark">*על מנת לשנות את כתובת הלקוח, יש ללכת לכרטיסיית הלקוח ולשנות אותה
				אצלו. שינוי הכתובת בפתיחת קריאה לא משפיע על כתובת הלקוח
			</div>
			<TextInput source="pickUpAddress.directions" label="PICK_UP_DIRECTIONS"/>
			<MultipleImagesInput source="pickUpAddress.directionsImagesUrls" label="PICK_UP_DIRECTIONS_IMAGES"/>
			<FormDataConsumer>
				{({
					  formData,
				  }) => {
					if (formData) {
						if (formData.type === EDeliveryType.PickUpDropOff) {
							return (<>
								<GoogleAddressInput source="dropOffAddress" label="DROP_OFF_ADDRESS"/>
								<TextInput source="dropOffAddress.directions" label="DROP_OFF_DIRECTIONS"/>
								<MultipleImagesInput source="dropOffAddress.directionsImagesUrls"
								                     label="DROP_OFF_DIRECTIONS_IMAGES"/>
							</>);
						}
					}
				}}
			</FormDataConsumer>
			<DateInput source="date" label="DATE" validate={[required()]} defaultValue={new Date()}/>
			{/*<SelectInput source="availableDays" label="AVAILABLE_DAYS" validate={[required()]}*/}
			{/*             choices={Object.keys(WeekFlags).map(key => ({*/}
			{/*	             id: key,*/}
			{/*	             name: EMatchType[key]*/}
			{/*             }))}*/}
			{/*/>*/}
			<WeekdaysInput source="availableDays" label="AVAILABLE_DAYS" defaultValue={
				{
					[EWeekday.Sunday]: true,
					[EWeekday.Monday]: true,
					[EWeekday.Tuesday]: true,
					[EWeekday.Wednesday]: true,
					[EWeekday.Thursday]: true,
				}}/>
			<TimeInput defaultValue="08:00" source="fromTime" label="FROM_TIME"/>
			<TimeInput defaultValue="17:00" source="toTime" label="TO_TIME"/>
			<TextInput multiline label="DELIVERY_NOTES" source="notes"/>
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <TextInput multiline label="PICK_UP_REMARKS" source="pickUpRemarks"/>}
			<BooleanInput source="isUrgent" label="IS_URGENT"/>
			<FormDataConsumer>
				{({
					  formData,
				  }) => {
					if (formData) {
						if (formData.isUrgent) {
							return (
								<div className="urgent-remark remark">*שים לב, מדובר בשליחות יקרה יותר - יש לוודא שאת/ה
									בעלי
									הסמכות לבצע פעולה זו</div>);
						}
					}
				}}
			</FormDataConsumer>
			<BooleanInput source="isLegal" label="IS_LEGAL"/>
			<FormDataConsumer>
				{({
					  formData,
				  }) => {
					if (formData) {
						if (formData.isLegal) {
							return (<>
								<UploadFile multiple source="filesUrls" label="FILES"/>
							</>);
						}
					}
				}}
			</FormDataConsumer>
			<FormDataConsumer>
				{({
					  formData,
				  }) => {
					if (formData) {
						if ([EDeliveryStatus.Failed, EDeliveryStatus.Done].includes(formData.status)) {
							return <>
								<h3>{translator('CLOSED_DELIVERY_FIELDS')}</h3>
								<TimeInput label="WAITING_TIME" source="waitingTime"/>
								<SelectInput source="failReason" label="FAIL_REASON"
								             choices={Object.keys(EDeliveryFailReason).map(key => ({
									             id: key,
									             name: EDeliveryFailReason[key]
								             }))}/>
								<SingleImageInput source="imageUrl" label="IMAGE"/>
								<NumberInput source="numberOfChecks" label="NUMBER_OF_CHECKS"/>
								<ReferenceInput
									label="RELATED_DELIVERY"
									source="relatedDeliveryId" reference="deliveries"
									alwaysOn
								>
									<AutocompleteInput label="RELATED_DELIVERY" optionText="id"/>
								</ReferenceInput>
							</>;
						}
					}
				}
				}
			</FormDataConsumer>
		</>
	);
};
