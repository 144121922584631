import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	TopToolbar,
} from 'react-admin';
import { DeliveryUpdateList } from './DeliveryUpdateList';
import { DeliveryUpdateDatagrid } from './DeliveryUpdateDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { DeliveryUpdate } from './DeliveryUpdate';

export * from './DeliveryUpdate';
export * from './DeliveryUpdateCreate';
export * from './DeliveryUpdateDatagrid';
export * from './DeliveryUpdateEdit';
export * from './DeliveryUpdateList';
export * from './DeliveryUpdateShow';

const DeliveryUpdatesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'deliveryId', header: 'מס׳ קריאה' },
	{ key: 'userId', header: 'מזהה משתמש' },
	{ key: 'content', header: 'תוכן' },
];

export const DeliveryUpdatesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: DeliveryUpdate) => entity;

	const deliveryUpdatesFilter = [
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('עדכונים על קריאה', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<ExportButton/>
						<CreateButton/>
					</TopToolbar>
				}
				{...props}
				filters={deliveryUpdatesFilter}
				bulkActionButtons={<DeliveryUpdatesBulkActionButtons/>}
				title="DELIVERY_UPDATES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <DeliveryUpdateList/> : <DeliveryUpdateDatagrid/>}
			</List>
		</Fragment>
	);
};
