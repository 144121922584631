import * as React from 'react';
import { TextField, useListContext } from 'react-admin';
import { Box } from '@mui/material';

export const VerificationList = () => {
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Box sx={{ display: 'flex', gap: '2rem', '& > *': { flex: '1' } }}>
                    <span>
                        {entity.phone?.phone}
                    </span>
					-
					<TextField record={entity} source="verificationCode" label="VERIFICATION_CODE"/>
				</Box>
			))}
		</div>
	);
};
