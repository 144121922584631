import { useFormContext } from 'react-hook-form';
import { Button, useInput, useTranslate } from 'react-admin';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const styles = {
	buttonGroup: {
		display: 'flex',
		marginBottom: '1rem',
	},
	button: {
		fontSize: '1rem',
		fontFamily: 'Rubik',
		marginRight: '1rem',
		'&[variant="contained"]': {
			border: '1px solid #1976d2',
			borderRadius: '.5rem'
		}
	},
	label: {
		marginRight: '1rem',
	},
};

export const TabsInput = ({ source, choices, label, ...props }) => {
	const form = useFormContext();
	const translator = useTranslate();
	const input = useInput({ source, ...props });
	const classes = styles;

	const translatedLabel = translator(label);

	return <div>
		<Box sx={classes.label}>{translatedLabel}</Box>
		<Box sx={classes.buttonGroup}>
			{choices.map(choice => (
				<Button
					key={choice.id}
					sx={classes.button}
					variant={input.field.value === choice.id ? 'contained' : 'outlined'}
					color="primary"
					onClick={() => {
						form.setValue(props.source, choice.id!);
						input.field.onChange(choice.id);
					}}
					{...props}
				>
					<Box>{translator(choice.name)}</Box>
				</Button>
			))}
		</Box>
	</div>;
};

TabsInput.propTypes = {
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	labelTime: PropTypes.string,
	className: PropTypes.string
};

TabsInput.defaultProps = {
	input: {},
	isRequired: false,
	meta: { touched: false, error: false },
	options: {},
	resource: '',
	source: '',
	labelTime: '',
	className: ''
};