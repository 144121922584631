import * as React from 'react';
import { DateField, Labeled, ReferenceField, TextField, useListContext } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

const deliveryUpdateStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardHeader: {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
	},
	cardContent: {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
	},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const DeliveryUpdateList = () => {
	const classes = deliveryUpdateStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						sx={classes.cardHeader}
						title={
							<Box sx={classes.cardTitleContent}>

								<Labeled label="USER" sx={classes.cardContentRow}>
									<ReferenceField record={entity} source="userId" label={false} reference="users">
										<TextField source="fullName" label={false}/>
									</ReferenceField>
								</Labeled>
								<DateField record={entity} locales="he-IL" source="_createdAt" showTime/>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<Labeled label="CONTENT" sx={classes.cardContentRow}>
							<TextField record={entity} source="content" label="CONTENT"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
