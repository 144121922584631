import * as React from 'react';
import {
	AutocompleteInput,
	BooleanInput,
	Edit,
	EditProps,
	ReferenceInput,
	required, SelectInput,
	SimpleForm,
	TextInput,
	usePermissions,
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import { EmployeeTitle } from './EmployeeTitle';
import { EApprovalStatus, EUserType } from '../enums';
import { formatPhoneNumber } from "../util/Utils";

export const EmployeeEdit = (props: EditProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Edit {...props} mutationMode="pessimistic" title={<EmployeeTitle/>}>
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                    <ReferenceInput
                        label="BUSINESS"
                        source="businessId" reference="businesses"
                        alwaysOn
                        validate={[required()]}
                    >
                        <AutocompleteInput label="BUSINESS" optionText="name" validate={[required()]}/>
                    </ReferenceInput>
				}
				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TextInput source="user.fullName" label="NAME" validate={[required()]}/>
				<TextInput
					type="tel"
					format={formatPhoneNumber} parse={formatPhoneNumber}
					sx={{ direction: 'ltr !important' }}
					source="user.phone.phone" label="PHONE" validate={[required()]}/>
				<TextInput source="user.email" label="EMAIL"/>
				<BooleanInput source="isOwner" label="IS_OWNER" validate={[required()]}/>
				<SelectInput source="user.approvalStatus" label="APPROVAL_STATUS" validate={[required()]}
				             defaultValue={EApprovalStatus.Approved}
				             choices={[EApprovalStatus.Approved, EApprovalStatus.Blocked].map(key => ({
					             id: key,
					             name: EApprovalStatus[key]
				             }))}/>
			</SimpleForm>
		</Edit>
	);
};
