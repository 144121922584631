import * as React from 'react';
import { useListContext, useTranslate } from 'react-admin';
import { Link } from '@mui/material';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import './EmployeeList.scss';
import { Business } from '../business';
import { useNavigate } from 'react-router';
import { Employee } from './Employee';
import { EApprovalStatus } from '../enums';
import { LOCAL_STORAGE_ME_DETAILS } from '../providers/constants';

export const EmployeeList = ({ businessId }: { businessId?: Business['id'] }) => {
	const translator = useTranslate();
	const navigate = useNavigate();
	const { data } = useListContext({
		filter: { business: businessId }
	});

	if (!data) {
		return null;
	}

	const storedUserData = localStorage.getItem(LOCAL_STORAGE_ME_DETAILS);
	let isNotOwner = false;

	if (storedUserData) {
		const userData = JSON.parse(storedUserData);

		if ((userData?.employee as Employee)?.isOwner === false) {
			isNotOwner = true;
		}
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="employees-container">
			{data.map((employee: Employee) => {
				if (employee)
					return (
						<div className="employee-row" onClick={() => navigate(`/employees/${employee.id}/show`)}
						     style={employee.user.approvalStatus === EApprovalStatus.Blocked ? { backgroundColor: '#ffaaaa' } : {}}>
							<div className="employee-row-details">
								<img className="employee-row-details-image"
								     alt={translator('EMPLOYEE')}
								     src={employee.profileImageUrl || '/assets/profile-image-placeholder.svg'}/>
								<span className="employee-row-details-name">{employee.user?.fullName}</span>
							</div>
							<div className="employee-row-contact">
								<Link className="employee-row-contact-email"
								      onClick={(event) => event.stopPropagation()}
								      target="_blank"
								      href={`mailto:${employee?.user?.email}`}><EmailIcon/></Link>
								<Link className="employee-row-contact-whatsapp"
								      onClick={(event) => event.stopPropagation()}
								      target="_blank"
								      href={`https://api.whatsapp.com/?p=${employee?.user?.phone?.prefix}${employee?.user?.phone?.phone}`}><WhatsappIcon/></Link>
								<Link className="employee-row-contact-phone"
								      onClick={(event) => event.stopPropagation()}
								      target="_blank"
								      href={`tel:${employee?.user?.phone?.phone}`}>
									<PhoneIcon/>
								</Link>
								{!isNotOwner && <Link className="employee-row-contact-phone"
                                                      onClick={(event) => {
									                      event.stopPropagation();
									                      event.preventDefault();
									                      navigate(`/employees/${employee.id}/edit`);
								                      }}>
                                    <EditIcon/>
                                </Link>}
							</div>
						</div>
						// <Card key={entity.id} sx={classes.card}>
						// 	<CardHeader
						// 		title={
						// 			<Box sx={classes.cardTitleContent}>
						// 				<span>{entity.name}</span>
						// 				<div>
						// 					<ShowButton
						// 						resource="employees"
						// 						record={entity}
						// 					/>
						// 					<EditButton
						// 						resource="employees"
						// 						record={entity}
						// 					/>
						// 				</div>
						// 			</Box>
						// 		}
						// 	/>
						// 	{/*<CardContent sx={classes.cardContent}>*/}
						// 	{/*	<Labeled label="#" sx={classes.cardContentRow}>*/}
						// 	{/*		<TextField record={entity} source="id" label="#"/>*/}
						// 	{/*	</Labeled>*/}
						// 	{/*	<Labeled label="PROFILE_IMAGE" sx={classes.cardContentRow}>*/}
						// 	{/*		<ImageField record={entity} source="profileImageUrl" label="PROFILE_IMAGE"/>*/}
						// 	{/*	</Labeled>*/}
						// 	{/*	<Labeled label="BUSINESS" sx={classes.cardContentRow}>*/}
						// 	{/*		<ReferenceField record={entity} source="businessId" label="FORUM" reference="businesses">*/}
						// 	{/*			<TextField source="name"/>*/}
						// 	{/*		</ReferenceField>*/}
						//
						// 	{/*	</Labeled>*/}
						// 	{/*	<Labeled label="NAME" sx={classes.cardContentRow}>*/}
						// 	{/*		<TextField record={entity} source="name" label="NAME"/>*/}
						// 	{/*	</Labeled>*/}
						// 	{/*	<Labeled label="PHONE" sx={classes.cardContentRow}>*/}
						// 	{/*		<PhoneField record={entity} source="user.phone.phone" label="PHONE"/>*/}
						// 	{/*	</Labeled>*/}
						// 	{/*	<Labeled label="EMAIL" sx={classes.cardContentRow}>*/}
						// 	{/*		<EmailField record={entity} source="user.email" label="EMAIL"/>*/}
						// 	{/*	</Labeled>*/}
						// 	{/*	<Labeled label="IS_OWNER" sx={classes.cardContentRow}>*/}
						// 	{/*		<BooleanField record={entity} source="isOwner" label="IS_OWNER"/>*/}
						// 	{/*	</Labeled>*/}
						// 	{/*</CardContent>*/}
						// </Card>
					);
				else
					return <></>;
			})}
		</div>
	);
};
