import React, { createElement, useRef } from 'react';
import Close from '@mui/icons-material/Close';
import { useInput, useRecordContext, useTranslate } from 'react-admin';
import GoogleAutocomplete from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import capitalize from '@mui/material/utils/capitalize';
import { Address } from '../interfaces/address.interface';
import { Box } from '@mui/material';
import './GoogleAddressInput.scss';

const PrivateGoogleAddressInput = (props) => {
	const form = useFormContext();
	const input = useInput({
		...props,
		validate: [...(props.validate ?? []), (value) => {
			if (props.isRequired) {
				if (!(value.addressStr && value.lon && value.lat)) {
					return translator('ADDRESS_IS_REQUIRED');
				}
			}
		}]
	});
	const translator = useTranslate();
	const inputRef: any = useRef();

	input.isRequired = props.isRequired;

	const record = useRecordContext();

	if (inputRef.current && form.getValues(props.source)?.addressStr) {
		inputRef.current.value = form.getValues(props.source).addressStr;
	}

	return (
		<div
			className={(input.formState.isValid || !input.fieldState.isTouched || !props.isRequired) ? 'GoogleAddressInput-input-container' : 'address-input-container invalid-address'}>
			<GoogleAutocomplete
				ref={inputRef}
				placeholder={`${props.label ? translator(props.label) : (input.isRequired ? 'הזנת מיקום *' : 'הזנת מיקום')}${input.isRequired ? ' *' : ''}`}
				apiKey="AIzaSyBMi6HhtQBJS5NzApnxrMe6BMZ5vdmaVQ0"
				defaultValue={record ? record[props.source]?.addressStr : undefined}
				inputAutocompleteValue={record ? record[props.source]?.addressStr : null}
				onPlaceSelected={(place) => {
					const address: Address = {
						...input.field.value,
						addressStr: inputRef?.current?.value,
						city: { name: place.address_components.find(c => c.types?.length > 1 && c.types?.includes('locality') && c.types?.includes('political'))?.long_name },
						lon: place?.geometry?.location?.lng(),
						lat: place?.geometry?.location?.lat()
					};

					form.setValue(props.source, address.addressStr!);
					return input.field.onChange(address);
				}}
				language="iw-IL"
				options={{
					types: [],
					componentRestrictions: {
						country: ['il']
					}
				}}
			/>
			{input.field.value && inputRef?.current?.value ?
				<div className="GoogleAddressInput-clear" onClick={(e) => {
					input.field.onChange(undefined);
					input.field.value = undefined;

					if (inputRef?.current) {
						inputRef.current.value = null;
					}
				}}>
					<Box width="1.5em" height="1.5em" className="icon">
						{createElement(Close, { fontSize: 'medium' })}
					</Box>
				</div> : <></>}
			{input.fieldState.error && <Box className="error">{input.fieldState.error?.message}</Box>}
		</div>
	);
};

const GoogleAddressInput = ({
	                            source,
	                            label,
	                            variant = 'p',
	                            isRequired = false,
	                            validate = [] as any[],
	                            ...props
                            }) => (
	<PrivateGoogleAddressInput
		{...props}
		label={label || capitalize(source.split('_').join(' '))}
		source={`${source}`}
		variant={variant}
		isRequired={isRequired}
		validate={validate}
		defaultValue={undefined}
	/>
);

export default GoogleAddressInput;
