import * as React from 'react';
import { EditButton, ShowButton, useListContext } from 'react-admin';
import { Box, Card, CardHeader } from '@mui/material';
import './BusinessList.scss';

const businessStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const BusinessList = () => {
	const classes = businessStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="businesses"
										record={entity}
									/>
									<EditButton
										resource="businesses"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					{/*<CardContent sx={classes.cardContent}>*/}
					{/*	<Labeled label="#" sx={classes.cardContentRow}>*/}
					{/*		<TextField record={entity} source="id" label="#"/>*/}
					{/*	</Labeled>*/}
					{/*	<Labeled label="PROFILE_IMAGE" sx={classes.cardContentRow}>*/}
					{/*		<ImageField record={entity} source="profileImageUrl" label="PROFILE_IMAGE"/>*/}
					{/*	</Labeled>*/}
					{/*	<Labeled label="NAME" sx={classes.cardContentRow}>*/}
					{/*		<TextField record={entity} source="name" label="NAME"/>*/}
					{/*	</Labeled>*/}
					{/*	<Labeled label="ADDRESS" sx={classes.cardContentRow}>*/}
					{/*		<TextField record={entity} source="address.addressStr" label="ADDRESS"/>*/}
					{/*	</Labeled>*/}
					{/*	<Labeled label="DIRECTIONS" sx={classes.cardContentRow}>*/}
					{/*		<TextField record={entity} source="directions" label="DIRECTIONS"/>*/}
					{/*	</Labeled>*/}
					{/*	<Labeled label="EMPLOYEES" sx={classes.cardContentRow}>*/}
					{/*		<ReferenceArrayField record={entity} source="employeesIds" label="EMPLOYEES" reference="employees">*/}
					{/*			<SingleFieldList>*/}
					{/*				<ChipField source="name"/>*/}
					{/*			</SingleFieldList>*/}
					{/*		</ReferenceArrayField>*/}
					{/*	</Labeled>*/}
					{/*</CardContent>*/}
				</Card>
			))}
		</div>
	);
};
