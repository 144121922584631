import * as React from 'react';
import {
	DateField,
	EditButton,
	FunctionField,
	Labeled,
	ReferenceField,
	ShowButton,
	TextField,
	usePermissions,
	useTranslate
} from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { TranslateField } from '../components/TranslateField';
import { Delivery } from './Delivery';
import { EUserType } from '../enums';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import { useNavigate } from 'react-router-dom';
import { LightboxImageField } from '../components/LightboxImageField';

const deliveryCardStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	statusLabel: {
		textAlign: 'start',
		width: 'fit-content',
		padding: '.5rem 1rem',
		boxShadow: '0px 0px 20px #27313f1c !important',
		marginLeft: 'auto',
		marginRight: '1rem',
		fontWeight: 'bold',
		borderRadius: '.5rem'
	},
	cardTitle: {
		fontSize: '1.2rem',
		textAlign: 'start'
	},
	cardSubtitle: {
		fontSize: '.9rem',
		textAlign: 'start',
		opacity: .7,
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'top',
		justifyContent: 'space-between'
	},
	cardTitleWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	cardTitleSummary: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '1rem'
	},
	cardLabelsRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'start',
		gap: '1rem',
		marginBottom: '1rem',
		alignItems: 'center',
		'& > *': {
			width: 'fit-content'
		}
	},
	cardTitleTextWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		justifyContent: 'space-between'
	},
	actionsContainer: {
		display: 'flex',
		height: 'fit-content'
	},
	cardContent: {
		paddingTop: 0
	},
	packageDetails: {
		display: 'flex',
		alignItems: 'center',
		gap: '2rem',
		justifyContent: 'space-between',
		width: '70%',
		margin: 'auto',
		marginTop: '2rem'
	},
	packageDetail: {
		display: 'flex',
		gap: '0.5rem',
		alignItems: 'center',
		flex: '1'
	},
	packageDetailsLabel: {
		'& p': {
			fontWeight: 'bold'
		}
	},
	cardContentRow: {
		display: 'flex',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold',
		gap: '.5rem',
		flexDirection: 'column',
		'& > *': {
			width: '100%',
		},
		'& .RaLabeled-label': {
			fontWeight: 'bold',
			textAlign: 'start',
			fontSize: '1rem',
		},
		'& .RaLabeled-label + *': {
			fontSize: '1rem',
			textAlign: 'start',
			whiteSpace: 'break-spaces !important',
		}
	},
	iconLabel: {
		display: 'flex',
		alignItems: 'center',
		gap: '1rem',
		marginBottom: '.5rem'
	}
};

export const DeliveryCard = ({ delivery }: { delivery: Delivery }) => {
	const classes = deliveryCardStyle;
	const { permissions } = usePermissions();
	const translator = useTranslate();
	const navigator = useNavigate();

	return (
		<Card key={delivery.id} sx={{ ...classes.card }}
		      onClick={() => {
			      navigator(`/deliveries/${delivery.id}/show`);
		      }}>
			<CardHeader
				title={
					<Box sx={classes.cardTitleWrapper}>
						<Box sx={classes.cardTitleSummary}>
							<FunctionField record={delivery} render={(record) => `#${record.id}`} label="#"/>
							<DateField locales="he-IL" record={delivery} source="_createdAt" label="DATE" showTime/>
						</Box>
						<Box sx={classes.cardLabelsRow}>
							<TranslateField sx={classes.statusLabel} record={delivery} source="status" label="STATUS"/>
							{delivery?.isUrgent && <FunctionField record={delivery} source="isUrgent" sx={{
								...classes.statusLabel,
								backgroundColor: 'red',
								color: 'white'
							}}
                                                                  render={(record, source) =>
								                                      <span>{translator('IS_URGENT_LABEL')}</span>}
                            />}
							{delivery?.isLegal && <FunctionField record={delivery} source="isLegal" sx={{
								...classes.statusLabel,
								backgroundColor: 'orange',
								color: 'white'
							}}
                                                                 render={(record, source) =>
								                                     <span>{translator('IS_LEGAL_LABEL')}</span>}
                            />}
						</Box>
						<Box sx={classes.cardTitleContent}>
							<Box sx={classes.cardTitleTextWrapper}>
								<ReferenceField reference="locations" source="locationId" record={delivery}>
									<TextField source="name" sx={classes.cardTitle}/>
								</ReferenceField>
								{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                                    <ReferenceField reference="businesses" source="businessId" record={delivery}>
                                        <Box sx={{ display: 'flex', alignItem: 'center' }}>
                                            <LightboxImageField source="profileImageUrl" sx={{
												img: {
													width: '30px !important',
													height: '30px !important',
													objectPosition: 'center',
													objectFit: 'cover'
												},
												width: '30px !important',
												height: '30px !important',
												display: 'flex',
												justifyContent: 'center',
												overflow: 'hidden',
												borderRadius: '50%',
												border: '1px solid #0000ff17'
											}}/>
                                            <TextField source="name" sx={classes.cardSubtitle}/>
                                        </Box>
                                    </ReferenceField>
								}
							</Box>
							<Box sx={classes.actionsContainer}>
								<ShowButton resource="deliveries" record={delivery}/>
								<EditButton resource="deliveries" record={delivery}/>
							</Box>
						</Box>
					</Box>
				}
			/>
			<CardContent sx={classes.cardContent}>
				{delivery.courierId && <Box sx={classes.iconLabel}>
                    <SportsMotorsportsIcon/>
                    <ReferenceField record={delivery} link="show" reference="couriers" source="courierId"
                                    label={false}>
                        <TextField source="user.fullName"/>
                    </ReferenceField>
                </Box>}
				<Box sx={classes.packageDetail}>
					<Labeled label="PICK_UP_ADDRESS" sx={classes.packageDetailsLabel}>
						<TextField record={delivery} source="pickUpAddress.addressStr" label="PICK_UP_ADDRESS"/>
					</Labeled>
				</Box>
				{delivery.dropOffAddress.addressStr ?
					<Box sx={classes.packageDetail}>
						<Labeled label="DROP_OFF_ADDRESS" sx={classes.packageDetailsLabel}>
							<TextField record={delivery} source="dropOffAddress.addressStr"/>
						</Labeled>
					</Box> : null}
				<Box sx={classes.packageDetail}>
					<Labeled label={translator('DELIVERY_DATE')} sx={classes.packageDetailsLabel}>
						<DateField locales="he-IL" source="date" record={delivery}/>
					</Labeled>
				</Box>
				<Box sx={classes.packageDetail}>
					<Labeled label={translator('LAST_STATUS_UPDATE_DATE')} sx={classes.packageDetailsLabel}>
						<DateField record={delivery} showTime locales="he-IL" source="lastStatusUpdateDate"/>
					</Labeled>
				</Box>
			</CardContent>
		</Card>
	);
};
