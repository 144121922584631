import * as React from 'react';
import { Employee } from './Employee';
import { Loading, ReferenceField, TextField, usePermissions, useRecordContext, useTranslate } from 'react-admin';
import './EmployeeProfile.scss';
import EmailIcon from '@mui/icons-material/Email';
import StoreIcon from '@mui/icons-material/Store';
import { IconButton, Link } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { EUserType } from '../enums';

const styles = {
	ctaIcon: {
		boxShadow: '1px 1px 7px #0000003d'
	}
};

export const EmployeeProfile = () => {
	const employee: Employee = useRecordContext();
	const translator = useTranslate();
	const { permissions } = usePermissions();

	if (employee)
		return <div className="employee-profile">
			<div className="employee-profile-image">
				<img src={employee.profileImageUrl || '/assets/profile-image-placeholder.svg'}
				     alt={translator('EMPLOYEE')}/>
			</div>
			<div className="employee-profile-name">
				<span>{employee?.user.fullName}</span>
			</div>
			<div className="employee-profile-contact">
				{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                    <div className="employee-profile-contact-detail">
                        <div className="employee-profile-contact-detail-icon"><StoreIcon/></div>
                        <div className="employee-profile-contact-detail-content">
                            <ReferenceField source="businessId" reference="businesses" label="BUSINESS">
                                <TextField source="name"/>
                            </ReferenceField>
                        </div>
                    </div>}
				<div className="employee-profile-contact-detail">
					<div className="employee-profile-contact-detail-icon"><EmailIcon/></div>
					<div className="employee-profile-contact-detail-content">{employee.user?.email}</div>
					<div className="employee-profile-contact-detail-cta">
						<IconButton sx={styles.ctaIcon} onClick={() => {
							const textField = document.createElement('textarea');
							textField.innerText = employee.user?.email;
							document.body.appendChild(textField);
							textField.select();
							document.execCommand('copy');
							textField.remove();
						}}><CopyAllIcon/></IconButton>
					</div>
				</div>
				<div className="employee-profile-contact-detail">
					<div className="employee-profile-contact-detail-icon"><PhoneIcon/></div>
					<div className="employee-profile-contact-detail-content">{employee.user?.phone?.phone}</div>
					<div className="employee-profile-contact-detail-cta">
						<Link target="_blank"
						      href={`https://wa.me/${employee.user?.phone.prefix}${employee.user?.phone.phone}`}><IconButton
							sx={styles.ctaIcon}><WhatsAppIcon/></IconButton></Link>
						<Link target="_blank" href={`tel:${employee.user?.phone.phone}`}><IconButton
							sx={styles.ctaIcon}><PhoneIcon/></IconButton></Link>
					</div>
				</div>
			</div>
		</div>;

	return <Loading/>;
};
