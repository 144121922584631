import { Layout } from 'react-admin';
import React from 'react';
import { CustomMenu } from './CustomMenu';
import { CustomAppBar } from './CustomAppBar';
import ScrollToBottomButton from "./ScrollToBottomButton";

export const CustomLayout = (props) => {
	return (
		<div>
			<Layout {...props} menu={CustomMenu} appBar={CustomAppBar}/>;
			<ScrollToBottomButton />
		</div>
	);
}
