import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const DeliveriesBarChart = ({ data }) => {
	return (
		<ResponsiveContainer width="100%" height={400}>
			<BarChart
				data={data}
				margin={{
					top: 20, right: 10, left: 10, bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="date" />
				<YAxis />
				<Tooltip />
				<Bar dataKey="deliveries" fill="#8884d8" />
			</BarChart>
		</ResponsiveContainer>
	);
};

export default DeliveriesBarChart;
