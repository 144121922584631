import * as React from 'react';
import { useListContext } from 'react-admin';
import { DeliveryCard } from './DeliveryCard';

export const DeliveryList = () => {
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<DeliveryCard delivery={entity} key={entity.id}/>
			))}
		</div>
	);
};
