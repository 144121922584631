import * as React from 'react';
import { Datagrid, EmailField, NumberField, TextField } from 'react-admin';
import { PhoneField } from '../components/PhoneField';
import './CourierDatagrid.scss';
import { LightboxImageField } from '../components/LightboxImageField';

export const CourierDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="couriers-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<LightboxImageField source="profileImageUrl" label="PROFILE_IMAGE" sortable={false}/>
			<TextField source="user.fullName" label="FULL_NAME"/>
			<PhoneField source="user.phone" label="PHONE" sortable={false}/>
			<EmailField source="user.email" label="EMAIL" sortable={false}/>
			<TextField source="circuitDriverId" label="CIRCUIT_DRIVER_ID"/>
			<NumberField source="workingRadius" label="WORKING_RADIUS"/>
			<NumberField source="currentNumberOfPackages" label="CURRENT_NUMBER_OF_PACKAGES" sortable={false}/>
		</Datagrid>
	);
};
