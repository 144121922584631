import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import Pagination from '../components/Pagination';
import { useLocation } from 'react-router-dom';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	ReferenceInput,
	TopToolbar,
	usePermissions,
} from 'react-admin';
import { EmployeeList } from './EmployeeList';
import { EmployeeDatagrid } from './EmployeeDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Employee } from './Employee';
import { EUserType } from '../enums';

export * from './Employee';
export * from './EmployeeCreate';
export * from './EmployeeDatagrid';
export * from './EmployeeEdit';
export * from './EmployeeList';
export * from './EmployeeShow';

const EmployeesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'profileImageUrl', header: 'תמונת פרופיל' },
	{ key: 'businessId', header: 'מס׳ ספק' },
	{ key: 'name', header: 'שם העובד' },
	{ key: 'user.phone.phone', header: 'טלפון' },
	{ key: 'user.email', header: 'מייל' },
	{ key: 'isOwner', header: 'בעלים?' },
];

export const EmployeesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Employee) => entity;
	const { permissions } = usePermissions();
	const location = useLocation();
	const [defaultFilterValues, setDefaultFilterValues] = useState<any | undefined>({});
	let initialBusinessId = useRef<string>();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const businessId = searchParams.get('businessId');

		if (businessId) {
			setDefaultFilterValues({ business: parseInt(businessId) });
			initialBusinessId.current = businessId;
		} else {
			setDefaultFilterValues(undefined);
		}
	}, [location.search]);

	const employeesFilter = [
		[EUserType.Admin, EUserType.SubAdmin].includes(permissions) && !initialBusinessId.current ?
			<ReferenceInput
				label="BUSINESS"
				source="business" reference="businesses"
				alwaysOn
			>
				<AutocompleteInput
					label="BUSINESS" optionText="name"/>
			</ReferenceInput> : null,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	].filter(v => v) as JSX.Element[];

	return (
		<Fragment>
			<List
				exporter={generateExporter('עובדים', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<ExportButton/>
						<CreateButton/>
					</TopToolbar>
				}
				{...props}
				filters={employeesFilter}
				filter={defaultFilterValues}
				// filterDefaultValues={businessId ? { business: parseInt(businessId) } : undefined}
				bulkActionButtons={<EmployeesBulkActionButtons/>}
				title="EMPLOYEES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <EmployeeList/> : <EmployeeDatagrid/>}
			</List>
		</Fragment>
	);
};
