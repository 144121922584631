import * as React from 'react';
import { Location } from './Location';
import { Loading, ReferenceField, TextField, usePermissions, useRecordContext, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Link } from '@mui/material';
import './LocationProfile.scss';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SubjectIcon from '@mui/icons-material/Subject';
import StoreIcon from '@mui/icons-material/Store';
import RouteIcon from '@mui/icons-material/Route';
import { Text } from 'recharts';
import PersonIcon from '@mui/icons-material/Person';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { EUserType } from '../enums';

const styles = {
	ctaIcon: {
		boxShadow: '1px 1px 7px #0000003d'
	}
};

export const LocationProfile = () => {
	const location: Location = useRecordContext();
	const translator = useTranslate();
	const navigate = useNavigate();
	const { permissions } = usePermissions();

	if (location)
		return <div className="location-profile">
			{/*<div className="location-profile-image">*/}
			{/*	<img src={location.profileImageUrl || '/assets/profile-image-placeholder.svg'} alt={translator('EMPLOYEE')}/>*/}
			{/*</div>*/}
			<div className="location-profile-name">
				<span>{location.name}</span>
			</div>
			<div className="location-profile-contact">
				{location.contactName ? (<div className="location-profile-contact-detail">
					<div className="location-profile-contact-detail-icon"><PersonIcon/></div>
					<div className="location-profile-contact-detail-content">{location?.contactName}</div>
					<div className="location-profile-contact-detail-cta">
						{location.contactEmail ? <IconButton sx={styles.ctaIcon} onClick={() => {
							const textField = document.createElement('textarea');
							textField.innerText = location?.contactEmail;
							document.body.appendChild(textField);
							textField.select();
							document.execCommand('copy');
							textField.remove();
						}}><CopyAllIcon/></IconButton> : null}
						{location.contactPhone ? <Link target="_blank"
						                               href={`https://wa.me/${location.contactPhone}`}><IconButton
							sx={styles.ctaIcon}><WhatsAppIcon/></IconButton></Link> : null}
						{location.contactPhone ? <Link target="_blank" href={`tel:${location.contactPhone}`}><IconButton
							sx={styles.ctaIcon}><PhoneIcon/></IconButton></Link> : null}
					</div>
				</div>) : null}
				{location.contactNameB ? (<div className="location-profile-contact-detail">
					<div className="location-profile-contact-detail-icon"><PersonIcon/></div>
					<div className="location-profile-contact-detail-content">{location?.contactNameB}</div>
					<div className="location-profile-contact-detail-cta">
						{location.contactEmailB ? <IconButton sx={styles.ctaIcon} onClick={() => {
							const textField = document.createElement('textarea');
							textField.innerText = location?.contactEmailB;
							document.body.appendChild(textField);
							textField.select();
							document.execCommand('copy');
							textField.remove();
						}}><CopyAllIcon/></IconButton> : null}
						{location.contactPhoneB ? <Link target="_blank"
						                                href={`https://wa.me/${location.contactPhoneB}`}><IconButton
							sx={styles.ctaIcon}><WhatsAppIcon/></IconButton></Link> : null}
						{location.contactPhoneB ?
							<Link target="_blank" href={`tel:${location.contactPhoneB}`}><IconButton
								sx={styles.ctaIcon}><PhoneIcon/></IconButton></Link> : null}
					</div>
				</div>) : null}
				{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                    <div className="location-profile-contact-detail">
                        <div className="location-profile-contact-detail-icon"><StoreIcon/></div>
                        <div className="location-profile-contact-detail-content">
                            <ReferenceField reference="businesses" source="businessId">
                                <TextField source="name"/>
                            </ReferenceField>
                        </div>
                    </div>}
				<div className="location-profile-contact-detail">
					<div className="location-profile-contact-detail-icon"><LocationOnIcon/></div>
					<div className="location-profile-contact-detail-content">{location.address?.addressStr}</div>
				</div>
				{location.address.directions ? <div className="location-profile-contact-detail">
					<div className="location-profile-contact-detail-icon"><RouteIcon/></div>
					<div className="location-profile-contact-detail-content">{location.address.directions}</div>
				</div> : null}
				{location.remarks ? <div className="location-profile-contact-detail">
					<div className="location-profile-contact-detail-icon"><SubjectIcon/></div>
					<div className="location-profile-contact-detail-content">{location.remarks}</div>
				</div> : null}
			</div>
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <div className="location-profile-ctas">
					{
						location.locationsGroupId ?
							<Button variant="outlined"
							        onClick={() => navigate(`/locations-groups/${location.locationsGroupId}/show`)}><span>{translator('SHOW_LOCATIONS_GROUP')}</span></Button> :
							<Text>{translator('NO_LOCATIONS_GROUP')}</Text>
					}
                </div>
			}
		</div>;

	return <Loading/>;
};
