import * as React from 'react';
import { Show, ShowProps, SimpleShowLayout, TextField, } from 'react-admin';

export const CityShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<TextField source="name" label="NAME"/>
			</SimpleShowLayout>
		</Show>
	);
};
