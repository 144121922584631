import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import { Box, FormControl, FormLabel } from '@mui/material';
import { EWeekday } from '../enums';

const weekdaysInputStyle = {
	container: {
		marginBottom: '2rem',
		width: '100%',
	},
	row: {
		width: '100%',
		display: 'flex',
		justifyContent: 'spaceBetween'
	},
	circle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '1.5rem',
		height: '1.5rem',
		padding: '.5rem',
		borderRadius: '50%',
		backgroundColor: '#ababab',
		cursor: 'pointer',
		color: 'white'
	},
	active: {
		backgroundColor: '#19d276',
	}
};

const WeekdaysInput = ({ source, label, defaultValue }) => {
	const classes = weekdaysInputStyle;
	const translator = useTranslate();
	const { field: { value, onChange } } = useInput({ source, defaultValue });

	const handleCircleClick = (day) => {
		const updatedValue = { ...value, [day]: !value[day] };
		onChange(updatedValue);
	};

	const weekdays = [EWeekday.Sunday, EWeekday.Monday, EWeekday.Tuesday, EWeekday.Wednesday, EWeekday.Thursday, EWeekday.Friday, EWeekday.Saturday];

	return (
		<FormControl sx={classes.container}>
			<FormLabel>{translator(label)}</FormLabel>
			<Box sx={classes.row}>
				{weekdays.map((day) => (
					<div key={day} style={{ display: 'inline-block', marginRight: '16px' }}>
						<Box sx={{ ...classes.circle, ...(value[day] ? classes.active : {}) }}
						     onClick={() => handleCircleClick(day)}>
							{translator(`${day.toUpperCase()}_SHORT`)}
						</Box>
					</div>
				))}
			</Box>
		</FormControl>
	);
};

export default WeekdaysInput;
