import * as React from 'react';
import { Show, ShowProps, } from 'react-admin';
import { LocationsGroupProfile } from './LocationsGroupProfile';
import { LocationsGroupTitle } from './LocationsGroupTitle';

export const LocationsGroupShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props} actions={false} title={<LocationsGroupTitle/>}>
			<LocationsGroupProfile/>
		</Show>
	);
};
