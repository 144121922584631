import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	FilterButton,
	List,
	ListProps,
	TextInput,
	TopToolbar,
} from 'react-admin';
import { LocationsGroupList } from './LocationsGroupList';
import { LocationsGroupDatagrid } from './LocationsGroupDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField } from '../util/Exporter';

export * from './LocationsGroup';
export * from './LocationsGroupCreate';
export * from './LocationsGroupDatagrid';
export * from './LocationsGroupEdit';
export * from './LocationsGroupList';
export * from './LocationsGroupShow';

const LocationsGroupBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'businessId', header: 'מס׳ ספק' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'name', header: 'שם' },
	{ key: 'remarks', header: 'הערות' },
];

export const LocationsGroupsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	// const exportDataMapper = (entity: Location) => entity;

	const locationsGroupsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn resettable/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				// exporter={generateExporter('לקוחות', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<CreateButton/>
					</TopToolbar>
				}
				exporter={false}
				{...props}
				filters={locationsGroupsFilter}
				bulkActionButtons={<LocationsGroupBulkActionButtons/>}
				title="LOCATIONS_GROUPS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <LocationsGroupList/> : <LocationsGroupDatagrid/>}
			</List>
		</Fragment>
	);
};
