import * as React from 'react';
import { useRecordContext, useTranslate, } from 'react-admin';
import { Employee } from './Employee';

export const EmployeeTitle = () => {
	const translator = useTranslate();
	const employee: Employee = useRecordContext();

	if (!employee) {
		return <span>{translator('EMPLOYEE')}</span>;
	}

	return <span>{employee?.user?.fullName}</span>;
};
