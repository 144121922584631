export enum EUserType {
	Admin = 'Admin',
	SubAdmin = 'SubAdmin',
	Employee = 'Employee',
	Courier = 'Courier',
	Regular = 'Regular',
}

export enum ESearchType {
	EQUALS = 'EQUALS',
	CONTAINS = 'CONTAINS',
	IN = 'IN',
	BETWEEN = 'BETWEEN'
}

export enum EUserStatus {
	Pending = 'Pending',
	Approved = 'Approved'
}

export enum NotificationType {
	SMS = 'SMS',
	Email = 'Email',
	All = 'All'
}

export enum EGender {
	Male = 'Male',
	Female = 'Female'
}

export enum EPlatform {
	AdminPanel = 'AdminPanel',
	App = 'App',
	Web = 'Web'
}

export enum EWeekday {
	Sunday = 'sunday',
	Monday = 'monday',
	Tuesday = 'tuesday',
	Wednesday = 'wednesday',
	Thursday = 'thursday',
	Friday = 'friday',
	Saturday = 'saturday',
}

export enum EDeliveryStatus {
	New = 'New',
	AssignToCourier = 'AssignToCourier',
	PickedUp = 'PickedUp',
	Done = 'Done',
	Failed = 'Failed',
}

export enum EDeliveryType {
	PickUp = 'PickUp',
	PickUpDropOff = 'PickUpDropOff'
}

export enum EDeliveryPackageType {
	Checks = 'Checks',
	Cash = 'Cash',
	Other = 'Other'
}

export enum EVehicleType {
	Motorbike = 'Motorbike',
	Car = 'Car',
	Bicycle = 'Bicycle'
}

export enum EDeliveryPackageSize {
	Small = 'Small',
	Medium = 'Medium',
	Big = 'Big',
}

export enum ENotificationType {
	Admin = 'Admin',
	Business = 'Business',
	Courier = 'Courier',
	Regular = 'Regular'
}

export enum ENotificationLabel {
	New = 'New',
	Done = 'Done',
	Update = 'Update',
	Attached = 'Attached',
	Urgent = 'Urgent',
	Deleted = 'Deleted',
	Failed = 'Failed',
}


export enum EDeliveryFailReason {
	SomeoneElsePicked = 'SomeoneElsePicked',
	WrongAddress = 'WrongAddress',
	ContactNotAvailable = 'ContactNotAvailable',
	ClosedLocation = 'ClosedLocation',
	CheckNotReady = 'CheckNotReady',
	Other = 'Other'
}

export enum EApprovalStatus {
	Pending = 'Pending',
	Approved = 'Approved',
	Blocked = 'Blocked'
}
