export const typography = {
	fontFamily: [
		'-apple-system',
		'Rubik',
	].join(','),
};

export const direction = 'rtl';

export const palette = {
	background: {
		default: '#fff',
	},
	text: {
		primary: '#09111f',
		secondary: '#09111f',
	},
	secondary: {
		main: '#25bae2'
	}
};

export const base = {
	typography,
	direction,
	palette
};
