import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Box } from '@mui/material';
import { EWeekday } from '../enums';

const weekdaysInputStyle = {
	container: {
		marginBottom: '2rem',
		width: '100%',
	},
	row: {
		width: '100%',
		display: 'flex',
		justifyContent: 'spaceBetween'
	},
	circle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '1.5rem',
		height: '1.5rem',
		padding: '.5rem',
		borderRadius: '50%',
		backgroundColor: '#ababab',
		cursor: 'pointer',
		color: 'white'
	},
	active: {
		backgroundColor: '#19d276',
	}
};

const WeekdaysField = ({ source }) => {
	const classes = weekdaysInputStyle;
	const translator = useTranslate();
	const record = useRecordContext();

	const weekdays = [EWeekday.Sunday, EWeekday.Monday, EWeekday.Tuesday, EWeekday.Wednesday, EWeekday.Thursday, EWeekday.Friday, EWeekday.Saturday];

	return (
		<Box sx={classes.row}>
			{weekdays.filter(day => record[source][day]).map((day) => (
				<div key={day} style={{ display: 'inline-block', marginRight: '16px' }}>
					<Box sx={{ ...classes.circle, ...(record[source][day] ? classes.active : {}) }}>
						{translator(`${day.toUpperCase()}_SHORT`)}
					</Box>
				</div>
			))}
		</Box>
	);
};

export default WeekdaysField;
