import * as React from 'react';
import { EditButton, ShowButton, useListContext, useTranslate } from 'react-admin';
import { Box, Card, CardHeader } from '@mui/material';
import { Courier } from './Courier';
import { EApprovalStatus } from '../enums';

const courierStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	},
	blocked: {
		color: 'red',
		fontSize: '1rem'
	}
};

export const CourierList = () => {
	const classes = courierStyle;
	const { data } = useListContext();
	const translator = useTranslate();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map((entity: Courier) => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.user?.fullName}</span>
								<div>
									{
										entity.user.approvalStatus === EApprovalStatus.Blocked &&
                                        <Box sx={classes.blocked}>{translator(EApprovalStatus.Blocked)}</Box>
									}
									<ShowButton
										resource="couriers"
										record={entity}
									/>
									<EditButton
										resource="couriers"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
				</Card>
			))}
		</div>
	);
};
