import * as React from 'react';
import { Create, EditProps, required, SelectInput, SimpleForm, TextInput, useTranslate } from 'react-admin';
import { ENotificationType } from '../enums';

export const NotificationsForm = (props: EditProps): React.ReactElement => {
	const translator = useTranslate();

	return (
		<Create {...props} resource="notifications">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<h3>{translator('SEND_NOTIFICATION_TO_CLIENTS')}</h3>
				<TextInput label="TITLE" source="title"/>
				<TextInput multiline label="CONTENT" source="body"/>
				<SelectInput source="type" label="USER_TYPE" validate={[required()]}
				             defaultValue={ENotificationType.Courier}
				             choices={[ENotificationType.Courier, ENotificationType.Business].map(key => ({
					             id: key,
					             name: ENotificationType[key]
				             }))}/>
			</SimpleForm>
		</Create>);
};
