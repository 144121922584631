import { useLocation, useNavigate } from 'react-router-dom';

import { AppBar, TitlePortal, UserMenu, RefreshIconButton } from 'react-admin';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Notifications from '@mui/icons-material/Notifications';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { httpClient } from '../providers/http_client';
import { API_URL, LOCAL_STORAGE_ME_DETAILS } from '../providers/constants';
import { Query } from '../interfaces/query.interface';
import { ESearchType } from '../enums';
import { Notification } from '../notifications';
import { serialize } from '../util/Serialize';

function usePrevious(value) {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const NotificationButton = () => {
	const navigate = useNavigate();
	const [notificationsCount, setNotificationsCount] = useState<number>();

	const fetchNotificationsCount = useCallback(async () => {
		const userDetails = localStorage.getItem(LOCAL_STORAGE_ME_DETAILS);

		if (userDetails) {
			const { user } = JSON.parse(userDetails!);

			const query: Partial<Query<Notification>> = {
				searchFields: user.lastNotificationsReadDate ? [{
					key: '_createdAt',
					from: new Date(user.lastNotificationsReadDate).toISOString(),
					type: ESearchType.BETWEEN
				},] : []
			};

			const { json } = await httpClient(`${API_URL}/notifications/count?${serialize(query)}`);

			setNotificationsCount(json.data);
		}
	}, []);

	useEffect(() => {
		fetchNotificationsCount();
	}, [fetchNotificationsCount]);

	const handleClick = () => {
		navigate('/notifications');
	};

	return (
		<div style={{ position: 'relative' }}>
			<Button
				sx={{ transform: 'scaleX(-1)' }}
				startIcon={<Notifications sx={{ direction: 'rtl' }}/>}
				color="primary"
				onClick={handleClick}
			><></>
			</Button>
			{(notificationsCount ?? 0) > 0 &&
                <span style={{
					width: '1.5rem',
					height: '1.5rem',
					position: 'absolute',
					backgroundColor: 'red',
					borderRadius: '50%',
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: 'white',
					top: '-0.25rem',
					fontSize: '.7rem',
				}}>{notificationsCount}</span>}
		</div>
	);
};

const BackButton = ({ basePath }: { basePath: string }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const prevLocation = usePrevious(location);

	const handleClick = () => {
		if (prevLocation && (prevLocation as any).pathname.includes(location.pathname.split('/')[1])) {
			navigate(-1);
		} else {
			navigate(basePath);
		}
	};

	return (
		<Button
			sx={{ transform: 'scaleX(-1)' }}
			startIcon={<ArrowBack sx={{ direction: 'rtl' }}/>}
			color="primary"
			onClick={handleClick}
		><></>
		</Button>
	);
};

export const CustomAppBar = ({ showRefreshButton = true, ...props }) => {
	const location = useLocation();
	const isSubResourcePage = location.pathname.split('/').filter(x => x).length > 1;
	const basePath = location.pathname.split('/').filter(x => x)[0];

	return <AppBar
		dir="rtl"
		sx={{
			direction: 'rtl',
			'& .RaAppBar-title': {
				flex: 1,
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
			},
		}}
		{...props}
		toolbar={
			<>
			{showRefreshButton && <RefreshIconButton />}
			</>
		}
		className={isSubResourcePage ? 'sub-resource' : undefined}
		userMenu={isSubResourcePage ? (
			<BackButton basePath={basePath}/>
		) : <div style={{ marginRight: 'auto' }}><UserMenu/></div>}
	>
		<TitlePortal/>
		<NotificationButton></NotificationButton>
	</AppBar>;
};
