import * as React from 'react';
import { ChipField, Datagrid, ReferenceArrayField, SingleFieldList, TextField } from 'react-admin';
import './LocationsGroupDatagrid.scss';

export const LocationsGroupDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="locations-groups-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<TextField source="name" label="NAME"/>
			<ReferenceArrayField source="locationsIds" label="LOCATIONS" reference="locations" sortable={false}>
				<SingleFieldList>
					<ChipField source="name"/>
				</SingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	);
};
