import * as React from 'react';
import {
	DeleteButton,
	Edit,
	EditProps,
	SaveButton,
	SimpleForm,
	useNotify,
	usePermissions, useRecordContext,
	useUpdate,
} from 'react-admin';
import { DeliveryForm } from './DeliveryForm';
import { useNavigate } from 'react-router-dom';
import { Delivery } from './Delivery';
import { EDeliveryStatus, EUserType } from "../enums";

export const DeliveryEdit = (props: EditProps): React.ReactElement => {
	const navigate = useNavigate();
	const notify = useNotify();
	const { permissions } = usePermissions();


	const [update, { isLoading }] = useUpdate();

	const updateDelivery = async (data: Delivery) => {
		delete data.deliveryPackagesIds;

		await update('deliveries', { data, id: data.id }, {
			onSuccess: () => {
				notify('ra.notification.updated', { type: 'success' });
				navigate(`/deliveries/`,);
			}
		});
	};

	const ConditionalDelete = props => {
		const record = useRecordContext<Delivery>(props);
		if (record.status === EDeliveryStatus.New ||
			record.status === EDeliveryStatus.AssignToCourier ||
			[EUserType.Admin].includes(permissions)) {
			return (
				<>
					<DeleteButton mutationMode="pessimistic"/>
				</>
			);
		} else {
			return (
				<>
				</>
			);
		}
	};

	return (
		<Edit {...props}>
			<SimpleForm sx={{ '& > *': { width: '100%' } }} onSubmit={updateDelivery as any} toolbar={
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<SaveButton
						disabled={isLoading}
						label="ra.action.save"
					/>
					<ConditionalDelete />
				</div>
			}>
				<DeliveryForm/>
			</SimpleForm>
		</Edit>
	);
};
