import * as React from 'react';
import { useRecordContext, useTranslate, } from 'react-admin';
import { Business } from './Business';

export const BusinessTitle = () => {
	const translator = useTranslate();
	const business: Business = useRecordContext();
	console.log(business);

	return <span>{translator('BUSINESS')} {business ? ` - ${business.name}` : ''}</span>;
};

