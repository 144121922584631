import Crop from '@mui/icons-material/Crop';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationSearching from '@mui/icons-material/LocationSearching';
import Employee from '@mui/icons-material/Badge';
import Package from '@mui/icons-material/Description';
import Motorbike from '@mui/icons-material/Moped';
import Lock from '@mui/icons-material/Lock';
import Suitcase from '@mui/icons-material/Work';
import AssessmentIcon from '@mui/icons-material/Assessment';

import React, { useState } from 'react';
import Dashboard from '@mui/icons-material/Dashboard';
import Notifications from '@mui/icons-material/Notifications';
import { MenuItemLink, useLogout, usePermissions, useTranslate } from 'react-admin';
import { Box } from '@mui/system';
import { EUserType } from '../enums';
import SubMenu from './SubMenu';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

interface MenuItem {
	name: string;
	text: string;
	icon: any;
	to?: () => string;
	items?: MenuItem[];
	isOpen?: boolean;
}

const adminMenuItems: MenuItem[] = [
	{ name: 'deliveries', text: 'DELIVERIES', icon: <Package/> },
	{ name: 'couriers', text: 'COURIERS', icon: <Motorbike/> },
	{ name: 'businesses', text: 'BUSINESSES', icon: <Suitcase/> },
	{ name: 'locations', text: 'LOCATIONS', icon: <LocationOnIcon/> },
	{ name: 'locations-groups', text: 'LOCATIONS_GROUPS', icon: <LocationSearching/> },
	{ name: 'employees', text: 'EMPLOYEES', icon: <Employee/> },
	{ name: 'cities', text: 'CITIES', icon: <Crop/> },
	// { name: 'admins', text: 'ADMINS', icon: <AdminPanelSettingsIcon/> },
	{ name: 'reports', text: 'REPORTS', icon: <AssessmentIcon/>, to: () => `reports` },
	{ name: 'verifications', text: 'VERIFICATIONS', icon: <Lock/> },
	{ name: 'notifications', text: 'NOTIFICATIONS', icon: <Notifications/> },
];

const businessItems: MenuItem[] = [
	{ name: 'deliveries', text: 'DELIVERIES', icon: <Package/> },
	{ name: 'locations', text: 'LOCATIONS', icon: <LocationOnIcon/> },
	{ name: 'employees', text: 'EMPLOYEES', icon: <Employee/> },
	{ name: 'reports', text: 'REPORTS', icon: <AssessmentIcon/>, to: () => `reports` },
	{ name: 'notifications', text: 'NOTIFICATIONS', icon: <Notifications/> },
];

const useStyles = {
	menuContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '240px',
		backgroundImage: 'linear-gradient(135deg, #2f3ea0, #ae342d)'
	},
	sideBarLogo: {
		maxHeight: '6rem',
		padding: '1rem'
	},
	sideBarLogoImage: {
		maxHeight: '24px'
	},
	sideBarLogoMinimized: {
		maxHeight: '2rem',
	},
	menuItem: {
		padding: '1rem !important',
		color: 'white !important',
		'& .MuiListItemIcon-root': {
			color: 'white'
		},
		'&[class*="-active"]': {
			fontWeight: 'bold',
			backgroundColor: '#ffffff66 !important'
		}
	},
	subMenuItem: {
		padding: '1rem 2rem !important'
	},
	logout: {
		marginTop: 'auto'
	}
};

export const CustomMenu = ({ onMenuClick }) => {
	const translator = useTranslate();
	const logout = useLogout();
	const { permissions } = usePermissions();
	const [state, setState] = useState(false);

	const classes = useStyles;

	const handleToggle = (item: MenuItem) => {
		item.isOpen = !item.isOpen;
		setState(!state);
	};

	console.log(permissions);

	return (
		<Box sx={classes.menuContainer} dir="rtl">
			<Box sx={classes.sideBarLogo}>
				<Box
					component="img"
					sx={classes.sideBarLogoImage} alt="Company Logo" src="/assets/logo_white.svg"/>
			</Box>
			{permissions === EUserType.Admin ? <MenuItemLink
				{...[] as any}
				to="/"
				exact="/"
				sx={useStyles.menuItem}
				primaryText={translator('DASHBOARD')}
				leftIcon={<Dashboard/>}
				onClick={onMenuClick}
			/> : null}
			{(permissions === EUserType.Admin ? adminMenuItems : businessItems).map(item =>
				item.items?.length ?
					<SubMenu
						handleToggle={() => handleToggle(item)}
						isOpen={item.isOpen ?? false}
						name={item.text}
						icon={item.icon}
						dense={false}
					>
						{(item.items.map(subItem =>
							<MenuItemLink
								{...[] as any}
								sx={{ ...useStyles.menuItem, ...useStyles.subMenuItem }}
								key={subItem.to ? subItem.to() : subItem.name}
								to={`/${item.to ? item.to() : item.name}/${subItem.to ? subItem.to() : subItem.name}`}
								primaryText={translator(subItem.text)}
								leftIcon={subItem.icon}
								onClick={onMenuClick}
								dense={true}
							/>
						))}
					</SubMenu>
					: <MenuItemLink
						{...[] as any}
						sx={useStyles.menuItem}
						key={item.to ? item.to() : item.name}
						to={`/${item.to ? item.to() : item.name}`}
						primaryText={translator(item.text)}
						leftIcon={item.icon}
						onClick={onMenuClick}
						dense={true}
					/>
			)}
			<Box sx={classes.logout}>
				<Button sx={{
					color: 'white',
					padding: '1rem',
					display: 'flex',
					alignItems: 'center',
					gap: '1rem',
					justifyContent: 'start'
				}} onClick={logout}>
					<LogoutIcon/><span>{translator('LOGOUT')}</span>
				</Button>
			</Box>
		</Box>
	);
};
