import * as React from 'react';
import { useState } from 'react';
import { Form, required, TextInput, useCreate, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const DeliveryUpdateCreateDialog = ({ open, handleClose }): React.ReactElement => {
	const [content, setContent] = useState('');
	const refresh = useRefresh();
	const notify = useNotify();
	const translator = useTranslate();
	const [create] = useCreate('delivery-updates');

	const onSubmit = async (delivery) => {
		create(
			'delivery-updates',
			{ data: { content, deliveryId: delivery.id } },
			{
				onSuccess: ({ newDeliveryUpdate }) => {
					refresh();
					notify(translator('DELIVERY_UPDATE_CREATED_SUCCESSFULLY'));
					handleClose();
				},
				onError: (error) => {
					notify(translator('FAILED_CREATING_DELIVERY_UPDATE'), { type: 'error' });
				}
			},
		);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<Form onSubmit={onSubmit}>
				<DialogTitle>{translator('CREATE_NEW_DELIVERY_UPDATE')}</DialogTitle>
				<DialogContent>
					<TextInput
						multiline
						source="content"
						label="CONTENT"
						validate={required()}
						onChange={(event) => setContent(event.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button type="submit">{translator('SAVE')}</Button>
					<Button onClick={handleClose}>{translator('CANCEL')}</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
};
