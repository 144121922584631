import * as React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useTranslate } from 'react-admin';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

const COLORS = [
	'#F99600',
	'#3BC4EE',
	'#FFC700',
	'#21D59B',
	'#0058FF',
	'#CA6EFF',
	'#ff6e6e',
];

const SegmentationChart = (props: {
	segmentation?: { name: string, value: number }[],
	key?: string,
	header: string,
	unit?: string,
	formatter?: Formatter<any, any>
}) => {
	const translator = useTranslate();
	const { segmentation, header } = props;
	if (!segmentation) return null;

	const prepareData = (segmentation: { name: string, value: number }[]) => {
		return segmentation.map(segment => ({
			name: translator(segment.name),
			value: parseInt(segment.value?.toString())
		}));
	};

	const data = prepareData(segmentation);

	return (
		<Card>
			<CardHeader title={translator(header ?? 'MONTHLY_PURCHASES')}/>
			<CardContent sx={{ paddingBottom: '3rem !important', paddingTop: '1rem' }}>
				<div style={{ width: '100%', maxWidth: '90vw', height: '100%' }}>
					<ResponsiveContainer height={180}>
						<PieChart>
							<Pie data={data} dataKey="value" nameKey="name" label={({
								                                                        cx,
								                                                        cy,
								                                                        midAngle,
								                                                        innerRadius,
								                                                        outerRadius,
								                                                        value,
								                                                        index
							                                                        }) => {
								const RADIAN = Math.PI / 180;
								// eslint-disable-next-line
								const radius = 25 + innerRadius + (outerRadius - innerRadius);
								// eslint-disable-next-line
								const x = cx + (radius + 30) * Math.cos(-midAngle * RADIAN);
								// eslint-disable-next-line
								const y = cy + radius * Math.sin(-midAngle * RADIAN);

								return (
									<text
										x={x}
										y={y}
										fill="black"
										textAnchor={x > cx ? 'start' : 'end'}
										dominantBaseline="central"
									>
										{value}
									</text>
								);
							}}>
								{data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
					<div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 2, marginTop: '1rem' }}>
						{data.map((entry, index) => (
							<div key={`legend-${header}-${index}`}
							     style={{ display: 'flex', alignItems: 'center', gap: '.5rem', width: 'fit-content' }}>
								<div style={{
									width: '1rem',
									height: '1rem',
									backgroundColor: COLORS[index % COLORS.length],
								}}/>
								<span style={{ whiteSpace: 'nowrap' }}>{entry.name}</span>
							</div>
						))}
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default SegmentationChart;
