import * as React from 'react';
import { Datagrid, DateField, ReferenceField, TextField, usePermissions } from 'react-admin';
import './DeliveryUpdateDatagrid.scss';
import { EUserType } from '../enums';

export const DeliveryUpdateDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="show" className="delivery-updates-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<DateField locales="he-IL" label="CREATED_AT" source="_createdAt" showTime/>
			<ReferenceField source="user.employeeId" reference="employees" label="EMPLOYEE" sortable={false}>
				<TextField source="user.fullName"/>
			</ReferenceField>

			<ReferenceField source="user.courierId" reference="couriers" label="COURIER"
			                sortable={false}
			                link={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? 'edit' : false}>
				<TextField source="user.fullName"/>
			</ReferenceField>

			<TextField source="content" label="CONTENT"/>
		</Datagrid>
	);
};
