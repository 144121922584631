import * as React from 'react';
import {
	AutocompleteInput,
	Create,
	CreateProps,
	ReferenceInput,
	required,
	SimpleForm,
	TextInput,
	usePermissions
} from 'react-admin';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { EUserType } from '../enums';
import { MultipleImagesInput } from '../components/MultipleImagesInput';
import { formatPhoneNumber } from "../util/Utils";

export const LocationCreate = (props: CreateProps): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Create {...props} redirect="show">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<TextInput source="name" label="BUSINESS_NAME" validate={[required()]}/>
				<TextInput source="contactName" label="CONTACT_NAME" validate={[required()]}/>
				<TextInput
					format={formatPhoneNumber} parse={formatPhoneNumber}
					type="tel" sx={{ direction: 'ltr !important' }} source="contactPhone"
				    label="CONTACT_PHONE" validate={[required()]}/>
				<TextInput type="email" source="contactEmail" label="CONTACT_EMAIL"/>
				<TextInput source="contactNameB" label="CONTACT_NAME_B"/>
				<TextInput
					format={formatPhoneNumber} parse={formatPhoneNumber}
					type="tel" sx={{ direction: 'ltr !important' }}
					source="contactPhoneB" label="CONTACT_PHONE_B"/>
				<TextInput type="email" source="contactEmailB" label="CONTACT_EMAIL_B"/>
				<GoogleAddressInput source="address" label="ADDRESS" validate={[required()]} isRequired/>
				{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                    <ReferenceInput
                        label="BUSINESS"
                        source="businessId" reference="businesses"
                        alwaysOn
                        validate={[required()]}
                    >
                        <AutocompleteInput label="BUSINESS" optionText="name" validate={[required()]}/>
                    </ReferenceInput>
				}
				<TextInput source="address.directions" label="DIRECTIONS"/>
				<MultipleImagesInput source="address.directionsImagesUrls" label="DIRECTIONS_IMAGES"/>
				<TextInput source="remarks" label="REMARKS"/>
			</SimpleForm>
		</Create>
	);
};
