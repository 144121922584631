import * as React from 'react';
import { AutocompleteInput, Create, CreateProps, ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';

export const DeliveryUpdateCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<ReferenceInput
					label="DELIVERY"
					source="deliveryId" reference="deliveries"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="DELIVERY" optionText="pickUpAddress.addressStr" validate={[required()]}/>
				</ReferenceInput>

				<ReferenceInput
					label="USER"
					source="userId" reference="users"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteInput label="USER" optionText="fullName" validate={[required()]}/>
				</ReferenceInput>

				<TextInput source="content" label="CONTENT" validate={[required()]}/>
			</SimpleForm>
		</Create>
	);
};
