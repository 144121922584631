import * as React from 'react';
import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, useTranslate } from 'react-admin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';

export const ExistingEntityModal = ({ resource, open, onRedirect, onOverride }) => {
	const translator = useTranslate();
	const [, setShowDialog] = useState(false);

	const handleCloseClick = () => {
		setShowDialog(false);
	};

	return (
		<Dialog
			fullWidth
			open={open}
			onClose={handleCloseClick}
			aria-label="Create post"
		>
			<DialogTitle>{translator(`${resource}_EXISTS`)}</DialogTitle>

			<DialogContent>
				{translator(`${resource}_EXISTS_DESCRIPTION`)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleCloseClick();
						onRedirect();
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
						<span style={{ fontSize: '1rem' }}>{translator('SHOW_ME')}</span>
						<VisibilityIcon/>
					</div>
				</Button>
				<Button
					onClick={() => {
						handleCloseClick();
						onOverride();
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
						<span style={{ fontSize: '1rem' }}>{translator('OVERRIDE_CREATION')}</span>
						<CreateIcon/>
					</div>
				</Button>
			</DialogActions>
		</Dialog>
	);
};
