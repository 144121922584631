import * as React from 'react';
import {
	AddItemButton,
	ArrayInput,
	BooleanInput,
	Create,
	CreateProps,
	required, SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { EApprovalStatus } from '../enums';

export const BusinessCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TextInput source="name" label="NAME" validate={[required()]}/>
				<GoogleAddressInput source="address" label="ADDRESS" validate={[required()]} isRequired/>
				<TextInput source="directions" label="DIRECTIONS"/>
				<TextInput source="deliveryPrice" label="DELIVERY_PRICE"/>
				<SelectInput source="approvalStatus" label="APPROVAL_STATUS" validate={[required()]}
				             defaultValue={EApprovalStatus.Approved}
				             choices={[EApprovalStatus.Approved, EApprovalStatus.Blocked].map(key => ({
					             id: key,
					             name: EApprovalStatus[key]
				             }))}/>
				<ArrayInput
					label="EMPLOYEES"
					source="employees"
					alwaysOn
				>
					<SimpleFormIterator disableReordering addButton={<AddItemButton label="הוספת עובד"/>}>
						<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
						<TextInput source="user.fullName" label="NAME" validate={[required()]}/>
						<TextInput source="user.phone.phone" label="PHONE" validate={[required()]} dir="ltr"
						           sx={{ direction: 'ltr !important' }}/>
						<TextInput source="user.email" label="EMAIL"/>
						<BooleanInput source="isOwner" label="IS_OWNER" validate={[required()]}/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	);
};
