export const englishMessages = {
	CLEAR_FILTERS: 'Clear filters',
	LOCATION_CONTACT_DETAILS: 'Location contact details',
	ra: {
		notification: {
			http_error: 'Network error. Please retry',
		},
		action: {
			save: 'Save',
			delete: 'Delete',
			remove_all_filters: 'Remove all filters',
		},
	}
};
