import * as React from 'react';
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin';

export const CityCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<TextInput source="name" label="NAME" validate={[required()]}/>
			</SimpleForm>
		</Create>
	);
};
