import * as React from 'react';
import { useState } from 'react';
import {
	Create,
	CreateProps,
	SaveButton,
	SimpleForm,
	useCreate,
	useDataProvider,
	useNotify,
	useRefresh
} from 'react-admin';
import { DeliveryForm } from './DeliveryForm';
import { Delivery } from './Delivery';
import { EDeliveryStatus } from '../enums';
import { ExistingEntityModal } from './ExistingEntityModal';
import { useNavigate } from 'react-router-dom';

export const DeliveryCreate = (props: CreateProps): React.ReactElement => {
	const navigate = useNavigate();
	const notify = useNotify();
	const dataProvider = useDataProvider();
	const [existingDelivery, setExistingDelivery] = useState<Delivery>();
	const [deliveryData, setDeliveryData] = useState({});
	const refresh = useRefresh();

	const [create, { isLoading }] = useCreate();

	const submitDeliveryCreation = async (deliveryToCreate: Delivery) => {
		if (deliveryToCreate.locationId) {
			const result = (await dataProvider.getList('deliveries', {
				pagination: { page: 1, perPage: 1 },
				sort: { field: 'id', order: 'DESC' },
				filter: {
					location: deliveryToCreate.locationId,
					business: deliveryToCreate.businessId,
					in_status: [EDeliveryStatus.New, EDeliveryStatus.AssignToCourier]
				}
			}));

			const delivery = result?.data[0];
			if (delivery) {
				setExistingDelivery(delivery);
				setDeliveryData(deliveryToCreate);
			} else {
				return await createDelivery(deliveryToCreate);
			}
		} else {
			return await createDelivery(deliveryToCreate);
		}
	};

	async function createDelivery(data) {
		await create('deliveries', { data }, {
			onSuccess: () => {
				notify('ra.notification.created', { type: 'success' });
				navigate(`/deliveries`);

				setTimeout(() => {
					refresh();
				}, 1000);
			}
		});

	}

	return (
		<>
			<Create {...props} redirect="list">
				<SimpleForm sx={{ '& > *': { width: '100%' } }} onSubmit={submitDeliveryCreation as any} toolbar={
					<SaveButton
						disabled={isLoading}
						label="ra.action.save"
					/>
				}>
					<DeliveryForm/>
				</SimpleForm>
			</Create>
			<ExistingEntityModal
				resource="deliveries"
				open={!!existingDelivery}
				onOverride={async () => {
					return createDelivery({ ...deliveryData, override: true });
				}}
				onRedirect={() => {
					navigate(`/deliveries/${existingDelivery?.id}/show`);
				}}
			/>
		</>
	);
};
