import React from 'react';
import { FileField, FunctionField, useRecordContext } from 'react-admin';

export const FileArrayField: any = ({ source, record }) => {
	const contextRecord = useRecordContext();

	record ??= contextRecord;

	console.log(record);
	console.log(contextRecord);

	return (
		<FunctionField source={source} record={record} render={(record, source) => {
			if (record[source]?.length > 0) {
				const files = {
					id: 1,
					files: (record[source] ?? []).map((file) => ({
						title: file.split('/').pop(),
						src: file
					}))
				};

				console.log(files);

				return <FileField target="_blank" record={files} source="files" src="src" title="title"/>;
			}
		}
		}/>
	);
};
