import * as React from 'react';
import { Delivery } from './Delivery';
import {
	DateField,
	FunctionField,
	Labeled,
	Loading,
	NumberField,
	ReferenceField,
	TextField,
	usePermissions,
	useRecordContext,
	useTranslate
} from 'react-admin';
import { Box, IconButton, Link } from '@mui/material';
import './DeliveryProfile.scss';
import { TranslateField } from '../components/TranslateField';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import DocumentIcon from '@mui/icons-material/FileOpen';
import DollarIcon from '@mui/icons-material/AttachMoney';
import PinIcon from '@mui/icons-material/Pin';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import HeightIcon from '@mui/icons-material/Height';
import WeekdaysField from '../components/WeekdaysField';
import { PriceField } from '../components/PriceField';
import { FileArrayField } from '../components/FileArrayField';
import { EDeliveryStatus, EUserType } from '../enums';
import _get from 'lodash-es/get';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { PersonLine } from './PersonLine';
import { LightboxImageField } from '../components/LightboxImageField';
import { PhoneField } from '../components/PhoneField';

const deliveryProfileStyle = {
	statusLabel: {
		textAlign: 'start',
		width: 'fit-content',
		padding: '.5rem 1rem',
		boxShadow: '0px 0px 20px #27313f1c !important',
		marginLeft: 'auto',
		marginRight: '1rem',
		fontWeight: 'bold',
		borderRadius: '.5rem'
	},
	packageDetails: {
		display: 'flex',
		flexDirection: 'column',
		gap: '.5rem',
		marginTop: '1rem'
	},
	packageDetail: {
		display: 'flex',
		alignItems: 'center',
		gap: '.5rem',
		flex: '1',
	},
	ctaIcon: {
		boxShadow: '1px 1px 7px #0000003d'
	}
};

export const DeliveryProfile = () => {
	const delivery: Delivery = useRecordContext();
	const classes = deliveryProfileStyle;
	const translator = useTranslate();
	const { permissions } = usePermissions();

	if (delivery)
		return <div className="delivery-profile">
			<TranslateField sx={classes.statusLabel} record={delivery} source="status" label="STATUS"/>

			<div className="delivery-profile-title">
				<ReferenceField link="show" reference="locations" source="locationId" label={false}>
					<TextField source="name"/>
				</ReferenceField>
				<DateField locales="he-IL" record={delivery} source="_createdAt" showTime label="DATE"
				           className="delivery-profile-title-date"/>
			</div>
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <div className="delivery-profile-subtitle">
                    <ReferenceField link="show" reference="businesses" source="businessId" label={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                </div>
			}
			<Box sx={classes.packageDetails}>
				{delivery.courierId &&
                    <Box sx={classes.packageDetail}>
                        <SportsMotorsportsIcon/>
                        <ReferenceField record={delivery} link="show" reference="couriers" source="courierId"
                                        label={false}>
                            <>
	                            <div><TextField source="user.fullName"/></div>
                                <div><PhoneField source="user.phone"/></div>
                            </>
                        </ReferenceField>
                    </Box>
				}
				<Box sx={classes.packageDetail}>
					<PinDropIcon/>
					<TextField record={delivery} source="pickUpAddress.addressStr" label="PICK_UP_ADDRESS"/>
				</Box>
				{delivery?.pickUpAddress?.directions &&
                    <Box sx={classes.packageDetail}>
                        <PinDropIcon/>
                        <TextField record={delivery} source="pickUpAddress.directions" label="DIRECTIONS"/>
                    </Box>}
				{delivery?.pickUpRemarks &&
                    <Box sx={classes.packageDetail}>
                        <PinDropIcon/>
                        <TextField record={delivery} source="pickUpRemarks" label="PICK_UP_REMARKS"/>
                    </Box>}
				{delivery.dropOffAddress.addressStr ?
					<Box sx={classes.packageDetail}>
						<SportsScoreIcon/>
						<TextField record={delivery} source="dropOffAddress.addressStr" label="DROP_OFF_ADDRESS"/>
					</Box> : null}
				<Box sx={classes.packageDetail}>
					<CalendarIcon/>
					<DateField locales="he-IL" source="date" record={delivery}/>
				</Box>
				<Box sx={classes.packageDetail}>
					<EditRoadIcon/>
					<span style={{ whiteSpace: 'nowrap' }}>{translator('LAST_STATUS_UPDATE_DATE')}</span>
					<DateField locales="he-IL" source="lastStatusUpdateDate" record={delivery} showTime/>
				</Box>
				{[EUserType.Admin].includes(permissions) && delivery.price ?
					<Box sx={classes.packageDetail}>
						<DollarIcon/>
						<PriceField source="price"/>
					</Box> : null}
				{delivery.deliveryPackages[0]?.checksDetails?.count ? <Box sx={classes.packageDetail}>
					<PinIcon/>
					<FunctionField
						render={(record, source) => {
							console.log(record);
							console.log(source);
							return `מס׳ צ׳קים לאיסוף - ${_get(record, source) || ''}`;
						}}
						source="deliveryPackages[0].checksDetails.count" record={delivery}/>
				</Box> : null}
				{delivery.deliveryPackages[0]?.size ? <Box sx={classes.packageDetail}>
					<HeightIcon/>
					<TranslateField source="deliveryPackages[0].size" record={delivery}/>
				</Box> : null}
				{delivery.createdById ? <Box sx={classes.packageDetail}>
					<PersonIcon/>
					<label>נוצר ע״י - </label>
					<ReferenceField source="createdById" reference="users" label="CREATED_BY">
						<PersonLine/>
					</ReferenceField>
				</Box> : null}
				{delivery.location?.contactName ? (
					<div>
						<label style={{ fontWeight: 'bold', fontSize: '.75rem' }}>איש קשר א׳</label>
						<div className="location-profile-contact-detail">
							<div className="location-profile-contact-detail-icon"><PersonIcon/></div>
							<div
								className="location-profile-contact-detail-content">{delivery.location?.contactName}</div>
							<div className="location-profile-contact-detail-cta">
								{delivery.location.contactEmail ? <IconButton sx={classes.ctaIcon} onClick={() => {
									const textField = document.createElement('textarea');
									textField.innerText = delivery.location?.contactEmail;
									document.body.appendChild(textField);
									textField.select();
									document.execCommand('copy');
									textField.remove();
								}}><CopyAllIcon/></IconButton> : null}
								{delivery.location.contactPhone ? <Link target="_blank"
								                                        href={`https://wa.me/${delivery.location.contactPhone}`}><IconButton
									sx={classes.ctaIcon}><WhatsAppIcon/></IconButton></Link> : null}
								{delivery.location.contactPhone ?
									<Link target="_blank" href={`tel:${delivery.location.contactPhone}`}><IconButton
										sx={classes.ctaIcon}><PhoneIcon/></IconButton></Link> : null}
							</div>
						</div>
					</div>
				) : null}
				{delivery.location?.contactNameB ? (
					<div>
						<label style={{ fontWeight: 'bold', fontSize: '.75rem' }}>איש קשר ב׳</label>
						<div className="location-profile-contact-detail">
							<div className="location-profile-contact-detail-icon"><PersonIcon/></div>
							<div
								className="location-profile-contact-detail-content">{delivery.location?.contactNameB}</div>
							<div className="location-profile-contact-detail-cta">
								{delivery.location?.contactEmailB ? <IconButton sx={classes.ctaIcon} onClick={() => {
									const textField = document.createElement('textarea');
									textField.innerText = delivery.location?.contactEmailB;
									document.body.appendChild(textField);
									textField.select();
									document.execCommand('copy');
									textField.remove();
								}}><CopyAllIcon/></IconButton> : null}
								{delivery.location?.contactPhoneB ? <Link target="_blank"
								                                          href={`https://wa.me/${delivery.location?.contactPhoneB}`}><IconButton
									sx={classes.ctaIcon}><WhatsAppIcon/></IconButton></Link> : null}
								{delivery.location?.contactPhoneB ?
									<Link target="_blank" href={`tel:${delivery.location?.contactPhoneB}`}><IconButton
										sx={classes.ctaIcon}><PhoneIcon/></IconButton></Link> : null}
							</div>
						</div>
					</div>
				) : null}
				{(delivery?.filesUrls) ? <Box sx={classes.packageDetail}>
					<DocumentIcon/>
					<FileArrayField source="filesUrls"/>
				</Box> : null}
				<Labeled label="AVAILABILITY">
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}>
						<WeekdaysField source="availableDays"/>
						{(delivery.fromTime && delivery.toTime) ? <>
							<TextField record={delivery} source="fromTime"/>-<TextField record={delivery}
							                                                            source="toTime"/>
						</> : null}
					</Box>
				</Labeled>
				{delivery?.notes ? <Box sx={classes.packageDetails}>
					<span style={{ fontSize: '1rem' }}>{translator('DELIVERY_NOTES')}</span>
					<TextField label="DELIVERY_NOTES" source="notes"/>
				</Box> : null}
				{[EDeliveryStatus.Failed, EDeliveryStatus.PickedUp, EDeliveryStatus.Done].includes(delivery.status) &&
                    <>
                        <label style={{ fontWeight: 'bold' }}>{translator('CLOSED_DELIVERY_FIELDS')}</label>
                        <>
							{delivery?.pickUpRemarks ? <Box sx={classes.packageDetails}>
								<span style={{ fontSize: '1rem' }}>{translator('PICK_UP_REMARKS')}</span>
								<TextField label="PICK_UP_REMARKS" source="pickUpRemarks"/>
							</Box> : null}
							{delivery?.waitingTime ? <Box sx={classes.packageDetails}>
								<span style={{ fontSize: '1rem' }}>{translator('WAITING_TIME')}</span>
								<TextField label="WAITING_TIME" source="waitingTime"/>
							</Box> : null}
							{delivery?.failReason ? <Box sx={classes.packageDetails}>
								<span style={{ fontSize: '1rem' }}>{translator('FAIL_REASON')}</span>
								<TranslateField source="failReason" label="FAIL_REASON"/>
							</Box> : null}
							{delivery?.imageUrl ? <Box sx={classes.packageDetails}>
								<span style={{ fontSize: '1rem' }}>{translator('IMAGE')}</span>
								<LightboxImageField record={delivery} source="imageUrl" label="IMAGE"/>
							</Box> : null}
							{delivery?.numberOfChecks ? <Box sx={classes.packageDetails}>
								<span style={{ fontSize: '1rem' }}>{translator('PICKED_UP_CHECKS')}</span>
								<NumberField source="numberOfChecks" label="PICKED_UP_CHECKS"/>
							</Box> : null}
							{delivery?.relatedDeliveryId ? <Box sx={classes.packageDetails}>
								<span style={{ fontSize: '1rem' }}>{translator('RELATED_DELIVERY')}</span>
								<ReferenceField
									label="RELATED_DELIVERY"
									source="relatedDeliveryId" reference="deliveries"
								>
									<TextField source="id"/>
								</ReferenceField>

							</Box> : null}
                        </>
                    </>
				}
			</Box>
		</div>;

	return <Loading/>;
};
