import * as React from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField, } from 'react-admin';

export const DeliveryUpdateShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<ReferenceField source="deliveryId" reference="deliveries" label="DELIVERY">
					<TextField source="name"/>
				</ReferenceField>

				<ReferenceField source="userId" reference="users" label="USER">
					<TextField source="fullName"/>
				</ReferenceField>

				<TextField source="content" label="CONTENT"/>
			</SimpleShowLayout>
		</Show>
	);
};
