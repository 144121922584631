import * as React from 'react';
import { List, Loading, useRecordContext, } from 'react-admin';
import { DeliveryUpdateDatagrid, DeliveryUpdateList } from '../delivery-update';
import { Theme, useMediaQuery } from '@mui/material';

export const DeliveryUpdatesView = (): React.ReactElement => {
	const record = useRecordContext();
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

	if (!record) {
		return <Loading/>;
	}

	return (
		<List
			actions={false}
			hasCreate={false}
			resource="delivery-updates"
			sort={{ field: '_createdAt', order: 'DESC' }}
			perPage={20}
			filter={{ delivery: record?.id }}
		>
			{isXSmall ? <DeliveryUpdateList/> : <DeliveryUpdateDatagrid/>}
		</List>
	);
};
