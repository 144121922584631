import React from 'react';
import Crop from '@mui/icons-material/Crop';
import { CitiesData, CityCreate, CityEdit, CityShow } from './city';

import { LocationsGroupCreate, LocationsGroupEdit, LocationsGroupsData, LocationsGroupShow } from './locations-group';
import { LocationCreate, LocationEdit, LocationsData, LocationShow } from './location';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Lock from '@mui/icons-material/Lock';
import Package from '@mui/icons-material/Description';
import Motorbike from '@mui/icons-material/Moped';
import Suitcase from '@mui/icons-material/Work';
import { EmployeeCreate, EmployeeEdit, EmployeesData, EmployeeShow } from './employee';

import Chat from '@mui/icons-material/Chat';
import { DeliveryUpdateCreate, DeliveryUpdatesData, DeliveryUpdateShow } from './delivery-update';
import { DeliveriesData, DeliveryCreate, DeliveryEdit, DeliveryShow } from './delivery';
import { CourierCreate, CourierEdit, CouriersData, CourierShow } from './courier';
import { BusinessCreate, BusinessEdit, BusinessesData, BusinessShow } from './business';

import Notifications from '@mui/icons-material/Notifications';
import { i18nProvider } from './providers/i18n.provider';
import GatewayProvider from './providers/gateway.provider';
import { jwtAuthProvider } from './providers/auth.provider';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { BrowserRouter, Route } from 'react-router-dom';
import { theme } from './theme';
import { CustomLayout } from './components/CustomLayout';
import { Dashboard } from './components/dashboard/Dashboard';
import './App.scss';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Reports } from './components/dashboard/Reports';
import { OtpLoginPage } from './auth/OtpLogin';
import { NotificationsData } from './notifications';
import { EUserType } from './enums';
import { NotificationsForm } from './notifications/NotificationsForm';
import { VerificationsData, VerificationShow } from './verification';

const App = (): React.ReactElement => {
	return (
		<div className="App" dir="rtl">
			<BrowserRouter>
				<Admin
					loginPage={OtpLoginPage}
					theme={theme}
					layout={CustomLayout}
					dashboard={Dashboard}
					title={'אפליקציית AnySpot'}
					dataProvider={GatewayProvider}
					authProvider={jwtAuthProvider}
					i18nProvider={i18nProvider}>
					<Resource
						icon={Crop}
						name="cities"
						options={{ label: 'CITIES' }}
						list={CitiesData}
						edit={CityEdit}
						create={CityCreate}
						show={CityShow}
					/>

					{permissions => (
						<>
							{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? <Resource
								icon={LocationSearchingIcon}
								name="locations-groups"
								options={{ label: 'LOCATIONS_GROUPS' }}
								list={LocationsGroupsData}
								edit={LocationsGroupEdit}
								create={LocationsGroupCreate}
								show={LocationsGroupShow}
							/> : null}

							{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? <Resource
								icon={Lock}
								name="verifications"
								options={{ label: 'VERIFICATIONS' }}
								list={VerificationsData}
								show={VerificationShow}
							/> : null}

							<Resource
								icon={LocationOnIcon}
								name="locations"
								options={{ label: 'LOCATIONS' }}
								list={LocationsData}
								edit={LocationEdit}
								create={LocationCreate}
								show={LocationShow}
							/>

							<Resource
								icon={AssessmentIcon}
								name="employees"
								options={{ label: 'EMPLOYEES' }}
								list={EmployeesData}
								edit={EmployeeEdit}
								create={EmployeeCreate}
								show={EmployeeShow}
							/>

							<Resource
								icon={Chat}
								name="delivery-updates"
								options={{ label: 'DELIVERY_UPDATES' }}
								list={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? DeliveryUpdatesData : undefined}
								// edit={DeliveryUpdateEdit}
								create={DeliveryUpdateCreate}
								show={DeliveryUpdateShow}
							/>

							<Resource
								icon={Package}
								name="deliveries"
								options={{ label: 'DELIVERIES' }}
								list={DeliveriesData}
								edit={DeliveryEdit}
								create={DeliveryCreate}
								show={DeliveryShow}
							/>

							<Resource
								icon={Motorbike}
								name="couriers"
								options={{ label: 'COURIERS' }}
								list={CouriersData}
								edit={CourierEdit}
								create={CourierCreate}
								show={CourierShow}
							/>

							<Resource
								icon={Suitcase}
								name="businesses"
								options={{ label: 'BUSINESSES' }}
								list={BusinessesData}
								edit={BusinessEdit}
								create={BusinessCreate}
								show={BusinessShow}
							/>

							<Resource
								icon={Notifications}
								name="notifications"
								options={{ label: 'NOTIFICATIONS' }}
								list={NotificationsData}
								hasEdit={false}
								hasShow={false}
								create={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? NotificationsForm : undefined}
							/>
						</>
					)}
					<CustomRoutes>
						<Route path="/reports" element={<Reports/>}/>
					</CustomRoutes>
				</Admin>
			</BrowserRouter>
		</div>
	);
};

export default App;
