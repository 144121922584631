import * as React from 'react';
import {
	AutocompleteArrayInput,
	Create,
	CreateProps,
	NumberInput,
	ReferenceArrayInput,
	required,
	SimpleForm,
	TextInput
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import { formatPhoneNumber } from "../util/Utils";

export const CourierCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TextInput source="user.fullName" label="FULL_NAME" validate={[required()]}/>
				<TextInput
					type="tel"
					format={formatPhoneNumber} parse={formatPhoneNumber}
					sx={{ direction: 'ltr !important' }}
					source="user.phone.phone" label="PHONE" validate={[required()]}/>
				<TextInput source="user.email" label="EMAIL"/>
				<NumberInput source="currentNumberOfPackages" label="CURRENT_NUMBER_OF_PACKAGES"/>
				<TextInput source="circuitDriverId" label="CIRCUIT_DRIVER_ID"/>
				<NumberInput source="workingRadius" label="WORKING_RADIUS"/>
				<ReferenceArrayInput
					label="PREFERRED_CITIES"
					source="preferredCitiesIds" reference="cities"
					alwaysOn
				>
					<AutocompleteArrayInput label="PREFERRED_CITIES" optionText="name"/>
				</ReferenceArrayInput>
			</SimpleForm>
		</Create>
	);
};
