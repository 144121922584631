import * as React from 'react';
import { Show, ShowProps, } from 'react-admin';
import { LocationProfile } from './LocationProfile';
import { LocationTitle } from './LocationTitle';

export const LocationShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props} title={<LocationTitle/>}>
			<LocationProfile/>
		</Show>
	);
};
