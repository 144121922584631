import * as React from 'react';
import { DateField, Identifier, Labeled, NumberField, TextField, useListContext } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { Notification } from './Notification';
import { useRedirect } from 'react-admin';
import { ENotificationLabel } from "../enums";

const notificationStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	urgentCard: {
		backgroundColor: '#f65654',
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardHeader: {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
	},
	cardContent: {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
		display: 'flex',
		flexDirection: 'column',
	},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'start',
		gap: '.5rem',
		margin: '0.5rem 0',
		fontWeight: 'bold',
		'& > p': {
			fontWeight: 'bold'
		}
	}
};

interface NotificationListProps {
	ids?: Identifier[];
	data?: { [key: number]: Notification };
	basePath?: string;
}

export const NotificationList = (props: NotificationListProps) => {
	const classes = notificationStyle;
	const { data } = useListContext();
	const redirect = useRedirect();


	if (!data) {
		return null;
	}

	// Define the click handler function
	const handleCardClick = (entity) => {
		if ((entity as Notification)?.deliveryId) {
			redirect(`/deliveries/${entity.deliveryId}/show`)
		} else {
			return false;
		}
	};

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card
					key={entity.id}
					sx={{ ...classes.card, ...([ENotificationLabel.Urgent].includes(entity.label) ? classes.urgentCard : {}) }}
					onClick={() => handleCardClick(entity)}>
					<CardContent sx={classes.cardContent}>
						<Labeled label="#" sx={classes.cardContentRow}>
							<NumberField record={entity} source="id" label="#"/>
						</Labeled>
						<Labeled label="CREATED_AT" sx={classes.cardContentRow}>
							<DateField record={entity} locales="he-IL" label="CREATED_AT" source="_createdAt" showTime/>
						</Labeled>
						<Labeled label="CONTENT" sx={classes.cardContentRow}>
							<TextField record={entity} source="content" label="CONTENT"/>
						</Labeled>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
