import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	ReferenceInput,
	TextInput,
	TopToolbar,
	usePermissions,
} from 'react-admin';
import { LocationList } from './LocationList';
import { LocationDatagrid } from './LocationDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { useLocation } from 'react-router-dom';
import { EUserType } from '../enums';

export * from './Location';
export * from './LocationCreate';
export * from './LocationDatagrid';
export * from './LocationEdit';
export * from './LocationList';
export * from './LocationShow';

const LocationsBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'businessId', header: 'מס׳ ספק' },
	{ key: 'address.addressStr', header: 'כתובת' },
	{ key: 'name', header: 'שם' },
	{ key: 'remarks', header: 'הערות' },
];

export const LocationsData = (props: ListProps): React.ReactElement => {
	const location = useLocation();
	const { permissions } = usePermissions();
	const [defaultFilterValues, setDefaultFilterValues] = useState<any | undefined>({});
	let initialBusinessId = useRef<string>();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const businessId = searchParams.get('businessId');
		let isDefaultFilterValues = false;

		if (businessId) {
			setDefaultFilterValues({ business: parseInt(businessId) });
			initialBusinessId.current = businessId;
			isDefaultFilterValues = true;
		}

		if (!isDefaultFilterValues) {
			setDefaultFilterValues(undefined);
		}
	}, [location.search]);

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Location) => entity;

	const locationsFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn resettable/>,
		(!initialBusinessId.current && [EUserType.Admin, EUserType.SubAdmin].includes(permissions)) ? <ReferenceInput
			label="BUSINESS"
			source="business" reference="businesses"
			alwaysOn
			initialValue={initialBusinessId.current ? { id: initialBusinessId.current } : undefined}
		>
			<AutocompleteInput
				label="BUSINESS" optionText="name"/>
		</ReferenceInput> : null,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	].filter(v => v) as JSX.Element[];

	return (
		<Fragment>
			<List
				exporter={generateExporter('לקוחות', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<ExportButton/>
						<CreateButton/>
					</TopToolbar>
				}
				{...props}
				filter={defaultFilterValues}
				filters={locationsFilter}
				bulkActionButtons={<LocationsBulkActionButtons/>}
				title="LOCATIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <LocationList/> : <LocationDatagrid/>}
			</List>
		</Fragment>
	);
};
