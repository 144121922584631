import React, { useState } from 'react';
import { Lightbox } from 'react-modal-image';
import { ImageField } from 'react-admin';

export const LightboxImageField = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div onClick={() => setIsOpen(true)}>
			<ImageField {...props}/>
			{isOpen && (
				<Lightbox
					small={props.record[props.source]}
					large={props.record[props.source]}
					showRotate={true}
					alt=""
					onClose={() => setIsOpen(false)}
				/>
			)}
		</div>
	);
};
