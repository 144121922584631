import { AuthProvider } from 'react-admin';
import { httpClient } from './http_client';
import { API_URL, LOCAL_STORAGE_BUSINESS_ID, LOCAL_STORAGE_ME_DETAILS, LOCAL_STORAGE_USER_TYPE } from './constants';
import { User } from '../interfaces/user.interface';
import { Employee } from '../employee';

function setLocalStorage(data: { employee: Employee, user: User }) {
	localStorage.setItem(LOCAL_STORAGE_ME_DETAILS, JSON.stringify(data));
	localStorage.setItem(LOCAL_STORAGE_USER_TYPE, (data.user as User).type);

	if (data.employee) {
		localStorage.setItem(LOCAL_STORAGE_BUSINESS_ID, (data.employee as Employee).businessId?.toString());
	}
}

export const jwtAuthProvider: AuthProvider = {
	login: async ({ phone, verificationCode }) => {
		const { json } = await httpClient(`${API_URL}/auth/verify`, {
			method: 'POST',
			body: JSON.stringify({
				phone: { phone, countryCode: 'IL', prefix: '972' },
				verificationCode
			})
		});

		if (json?.data) {
			setLocalStorage(json.data);
		}
	},
	// login: async ({ username, password }) => {
	// 	const { json } = await httpClient(`${apiUrl}/auth/login`, {
	// 		method: 'POST',
	// 		body: JSON.stringify({ email: username, password })
	// 	});
	//
	// 	localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(json.data));
	// },
	checkError: (error) => {
		const status = error?.status;

		console.log(`status - ${status}`);

		if (status === 401 || status === 403) {
			return Promise.reject({ message: false });
		}
		return Promise.resolve();
	},
	checkAuth: () => localStorage.getItem(LOCAL_STORAGE_ME_DETAILS)
		? Promise.resolve()
		: Promise.reject({ message: false }),
	logout: () => {
		localStorage.removeItem(LOCAL_STORAGE_ME_DETAILS);
		localStorage.removeItem(LOCAL_STORAGE_USER_TYPE);
		localStorage.removeItem(LOCAL_STORAGE_BUSINESS_ID);
		return Promise.resolve();
	},
	getIdentity: async () => {
		const { json } = await httpClient(`${API_URL}/auth/me`, { method: 'GET' });

		if (json?.data) {
			setLocalStorage(json.data);
		}

		return {
			id: json.data.user?.id,
			fullName: json.data.user?.fullName ?? json.data.employee?.name ?? json.data.courier?.name ?? 'שם לא ידוע',
			avatar: json.data.user?.profileImageUrl
		};
	},
	getPermissions: params => {
		const storedUserData = localStorage.getItem(LOCAL_STORAGE_ME_DETAILS);

		if (storedUserData) {
			const userData = JSON.parse(storedUserData);

			if (userData.user) {
				return Promise.resolve((userData.user as User).type);
			}
		}

		return Promise.reject('error.not-authenticated');
	}
};
