import * as React from 'react';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

import { ImageInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { httpClient } from '../providers/http_client';
import { API_URL } from '../providers/constants';
import { LightboxImageField } from './LightboxImageField';

const PreviewImage = ({ record, source, image, isLoading }: any) => {
	if (typeof (record) == 'string') {
		record = {
			[source]: record
		};
	}

	if (isLoading) {
		return <CircularProgress/>;
	} else if (image) {
		return <img src={URL.createObjectURL(image)} alt=""/>;
	} else {
		return <LightboxImageField record={record} source={source}/>;
	}
};

export const SingleImageInput = ({ record, source, ...props }: any) => {
	const recordByContext = useRecordContext();
	record = record ?? recordByContext;
	const form = useFormContext();
	const [image, setImage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const onFileChange = async (file: any) => {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			setImage(file);

			setIsLoading(true);
			const { json } = await httpClient(`${API_URL}/files/upload/single`, {
				method: 'POST',
				headers: new Headers({ 'ContentType': 'multipart/form-data' }),
				body: formData
			});
			setIsLoading(false);

			form.setValue(source, json.data);
		}
	};

	return <ImageInput {...props}
	                   source={source}
	                   onChange={onFileChange}
	>
		<PreviewImage image={image} source={source} record={record} isLoading={isLoading}/>
	</ImageInput>;
};
