import * as React from 'react';
import { useState } from 'react';
import { CreateButton, ResourceContextProvider, Show, ShowProps, TabbedShowLayout, } from 'react-admin';
import { DeliveryProfile } from './DeliveryProfile';
import { DeliveryTitle } from './DeliveryTitle';
import { DeliveryUpdatesView } from './DeliveryUpdatesView';
import { DeliveryUpdateCreateDialog } from '../delivery-update/DeliveryUpdateCreateDialog';

export const DeliveryShow = (props: ShowProps): React.ReactElement => {
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogOpen = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setDialogOpen(true);
	};

	return (
		<Show {...props} title={<DeliveryTitle/>}>
			<TabbedShowLayout>
				<TabbedShowLayout.Tab label="DETAILS">
					<DeliveryProfile/>
				</TabbedShowLayout.Tab>
				<TabbedShowLayout.Tab label="UPDATES" path="updates">
					<DeliveryUpdatesView/>
					<ResourceContextProvider value="delivery-updates">
						<>
							<DeliveryUpdateCreateDialog open={dialogOpen} handleClose={handleDialogClose}/>
							<CreateButton onClick={handleDialogOpen} resource="delivery-updates"/>
						</>
					</ResourceContextProvider>
				</TabbedShowLayout.Tab>
			</TabbedShowLayout>
		</Show>
	);
};
