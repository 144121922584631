import * as React from 'react';
import {
	AutocompleteArrayInput,
	Create,
	CreateProps,
	ReferenceArrayInput,
	required,
	SimpleForm,
	TextInput
} from 'react-admin';

export const LocationsGroupCreate = (props: CreateProps): React.ReactElement => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<TextInput source="name" label="NAME" validate={[required()]}/>
				<ReferenceArrayInput
					label="LOCATIONS"
					source="locationsIds" reference="locations"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteArrayInput label="LOCATIONS" optionText="name" validate={[required()]}/>
				</ReferenceArrayInput>

			</SimpleForm>
		</Create>
	);
};
