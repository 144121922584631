import * as React from 'react';
import {
	AutocompleteArrayInput,
	Edit,
	EditProps,
	NumberInput,
	ReferenceArrayInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import { CourierTitle } from './CourierTitle';
import { EApprovalStatus } from '../enums';
import { formatPhoneNumber } from "../util/Utils";

export const CourierEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic" title={<CourierTitle/>}>
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TextInput source="user.fullName" label="FULL_NAME" validate={[required()]}/>
				<TextInput
					type="tel"
					format={formatPhoneNumber} parse={formatPhoneNumber}
					sx={{ direction: 'ltr !important' }}
					source="user.phone.phone" label="PHONE" validate={[required()]}
				/>
				<TextInput source="user.email" label="EMAIL"/>
				<NumberInput source="currentNumberOfPackages" label="CURRENT_NUMBER_OF_PACKAGES"/>
				<TextInput source="circuitDriverId" label="CIRCUIT_DRIVER_ID"/>
				<NumberInput source="workingRadius" label="WORKING_RADIUS"/>
				<SelectInput source="user.approvalStatus" label="APPROVAL_STATUS" validate={[required()]}
				             defaultValue={EApprovalStatus.Approved}
				             choices={[EApprovalStatus.Approved, EApprovalStatus.Blocked].map(key => ({
					             id: key,
					             name: EApprovalStatus[key]
				             }))}/>
				<ReferenceArrayInput
					label="PREFERRED_CITIES"
					source="preferredCitiesIds" reference="cities"
					alwaysOn
				>
					<AutocompleteArrayInput label="PREFERRED_CITIES" optionText="name"/>
				</ReferenceArrayInput>
			</SimpleForm>
		</Edit>
	);
};
