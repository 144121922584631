import * as React from 'react';
import { Datagrid, TextField } from 'react-admin';
import './BusinessDatagrid.scss';
import { PriceField } from '../components/PriceField';
import { LightboxImageField } from '../components/LightboxImageField';
import { TranslateField } from '../components/TranslateField';

export const BusinessDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="businesses-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<LightboxImageField source="profileImageUrl" label="PROFILE_IMAGE" sortable={false}/>
			<TextField source="name" label="NAME"/>
			<TextField source="address.addressStr" label="ADDRESS" sortable={false}/>
			<TextField source="directions" label="DIRECTIONS" sortable={false}/>
			<PriceField source="deliveryPrice" label="DELIVERY_PRICE"/>
			<TranslateField source="approvalStatus" label="APPROVAL_STATUS"/>
			{/*<ReferenceArrayField source="employeesIds" label="EMPLOYEES" reference="employees" sortable={false}>*/}
			{/*	<SingleFieldList>*/}
			{/*		<ChipField source="user.fullName"/>*/}
			{/*	</SingleFieldList>*/}
			{/*</ReferenceArrayField>*/}
		</Datagrid>
	);
};
