import * as React from 'react';
import { Show, ShowProps, } from 'react-admin';
import { CourierProfile } from './CourierProfile';
import { CourierTitle } from './CourierTitle';

export const CourierShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props} actions={false} title={<CourierTitle/>}>
			<CourierProfile/>
		</Show>
	);
};
