import * as React from 'react';
import { Datagrid, TextField } from 'react-admin';
import './VerificationDatagrid.scss';
import { PhoneField } from '../components/PhoneField';

export const VerificationDatagrid = (): React.ReactElement => {
	return (
		<Datagrid rowClick="edit" className="verifications-table" optimized
		          bulkActionButtons={false}
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<TextField source="verificationCode" label="VERIFICATION_CODE"/>
			<PhoneField source="phone" label="PHONE"/>
		</Datagrid>
	);
};
