import * as React from 'react';
import { Edit, EditProps, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import GoogleAddressInput from '../components/GoogleAddressInput';
import { BusinessTitle } from './BusinessTitle';
import { EApprovalStatus } from '../enums';

export const BusinessEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic" title={<BusinessTitle/>}>
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TextInput source="name" label="NAME" validate={[required()]}/>
				<GoogleAddressInput source="address" label="ADDRESS" validate={[required()]} isRequired/>
				<TextInput source="directions" label="DIRECTIONS"/>
				<TextInput source="deliveryPrice" label="DELIVERY_PRICE"/>
				<SelectInput source="approvalStatus" label="APPROVAL_STATUS" validate={[required()]}
				             choices={[EApprovalStatus.Approved, EApprovalStatus.Blocked].map(key => ({
					             id: key,
					             name: EApprovalStatus[key]
				             }))}/>
			</SimpleForm>
		</Edit>
	);
};
