import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	FilterButton,
	List,
	ListProps,
	TextInput,
	TopToolbar,
} from 'react-admin';
import { BusinessList } from './BusinessList';
import { BusinessDatagrid } from './BusinessDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField } from '../util/Exporter';

export * from './Business';
export * from './BusinessCreate';
export * from './BusinessDatagrid';
export * from './BusinessEdit';
export * from './BusinessList';
export * from './BusinessShow';

const BusinessesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'profileImageUrl', header: 'תמונת פרופיל' },
	{ key: 'name', header: 'שם' },
	{ key: 'address', header: 'כתובת' },
	{ key: 'directions', header: 'הנחיות' },
	{ key: 'employees', header: 'עובדים' },
];

export const BusinessesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	// const exportDataMapper = (entity: Business) => entity;

	const businessesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn resettable/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				// exporter={generateExporter('ספקים', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<CreateButton/>
					</TopToolbar>
				}
				exporter={false}
				{...props}
				filters={businessesFilter}
				bulkActionButtons={<BusinessesBulkActionButtons/>}
				title="BUSINESSES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <BusinessList/> : <BusinessDatagrid/>}
			</List>
		</Fragment>
	);
};
