import * as React from 'react';
import { useRecordContext, useTranslate, } from 'react-admin';
import { LocationsGroup } from './LocationsGroup';

export const LocationsGroupTitle = () => {
	const translator = useTranslate();
	const locationsGroup: LocationsGroup = useRecordContext();
	console.log(locationsGroup);

	return <span>{translator('LOCATIONS_GROUP')} {locationsGroup ? ` - ${locationsGroup.name}` : ''}</span>;
};

