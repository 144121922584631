import * as React from 'react';
import { Show, ShowProps, } from 'react-admin';
import { EmployeeProfile } from './EmployeeProfile';
import { EmployeeTitle } from './EmployeeTitle';

export const EmployeeShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props} actions={false} title={<EmployeeTitle/>}>
			<EmployeeProfile/>
		</Show>
	);
};
