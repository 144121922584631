import * as React from 'react';
import {
	AutocompleteArrayInput,
	Edit,
	EditProps,
	ReferenceArrayInput,
	required,
	SimpleForm,
	TextInput,
} from 'react-admin';

export const LocationsGroupEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<TextInput source="name" label="NAME" validate={[required()]}/>
				<ReferenceArrayInput
					label="LOCATIONS"
					source="locationsIds" reference="locations"
					alwaysOn
					validate={[required()]}
				>
					<AutocompleteArrayInput label="LOCATIONS" optionText="name" validate={[required()]}/>
				</ReferenceArrayInput>

			</SimpleForm>
		</Edit>
	);
};
