import * as React from 'react';
import { useRecordContext, useTranslate, } from 'react-admin';
import { Courier } from './Courier';

export const CourierTitle = () => {
	const translator = useTranslate();
	const courier: Courier = useRecordContext();
	console.log(courier);

	return <span>{translator('COURIER')} {courier ? ` - ${courier.user?.fullName}` : ''}</span>;
};
