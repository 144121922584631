import * as React from 'react';
import { IconButton, Link } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useRecordContext } from 'react-admin';

const personLineStyles = {
	ctaIcon: {
		boxShadow: '1px 1px 7px #0000003d'
	}
};

export const PersonLine = ({ name, phone, email }: { name?: string, phone?: string, email?: string }) => {
	const record = useRecordContext();
	const classes = personLineStyles;
	console.log(record);

	name ??= record?.fullName;
	phone ??= record?.phone?.phone;
	email ??= record?.email;

	return (
		<div className="location-profile-contact-detail">
			<div
				className="location-profile-contact-detail-content">{name}</div>
			<div className="location-profile-contact-detail-cta">
				{email ? <IconButton sx={classes.ctaIcon} onClick={() => {
					const textField = document.createElement('textarea');
					textField.innerText = email ?? '';
					document.body.appendChild(textField);
					textField.select();
					document.execCommand('copy');
					textField.remove();
				}}><CopyAllIcon/></IconButton> : null}
				{phone ? <Link target="_blank"
				               href={`https://wa.me/${phone}`}><IconButton
					sx={classes.ctaIcon}><WhatsAppIcon/></IconButton></Link> : null}
				{phone ?
					<Link target="_blank" href={`tel:${phone}`}><IconButton
						sx={classes.ctaIcon}><PhoneIcon/></IconButton></Link> : null}
			</div>
		</div>);
};
