import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
	AutocompleteInput,
	BooleanInput,
	Create,
	CreateProps,
	Labeled,
	ReferenceField,
	ReferenceInput,
	required,
	SimpleForm,
	TextField,
	TextInput,
	usePermissions
} from 'react-admin';
import { SingleImageInput } from '../components/SingleImageInput';
import { useLocation } from 'react-router-dom';
import { EUserType } from '../enums';
import { formatPhoneNumber } from "../util/Utils";

export const EmployeeCreate = (props: CreateProps): React.ReactElement => {
	const location = useLocation();
	const { permissions } = usePermissions();
	const [, setDefaultFilterValues] = useState<any | undefined>({});
	let initialBusinessId = useRef<string>();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const businessId = searchParams.get('businessId');

		if (businessId) {
			setDefaultFilterValues({ business: parseInt(businessId) });
			initialBusinessId.current = businessId;
		} else {
			setDefaultFilterValues(undefined);
		}
	}, [location.search]);

	return (
		<Create {...props} redirect="list">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
					(initialBusinessId.current ?
						<Labeled label="BUSINESS" sx={{
							display: 'flex', gap: '1rem', flexDirection: 'row', '& .RaLabeled-label': {
								fontWeight: 'bold',
								textAlign: 'start',
								fontSize: '1rem',
							}
						}}>
							<ReferenceField source="id" reference="businesses" label="BUSINESS">
								<TextField source="name"/>
							</ReferenceField>
						</Labeled> :
						<ReferenceInput
							label="BUSINESS"
							source="businessId" reference="businesses"
							alwaysOn
							validate={[required()]}
						>
							<AutocompleteInput label="BUSINESS" optionText="name" validate={[required()]}/>
						</ReferenceInput>)
				}

				<SingleImageInput source="profileImageUrl" label="PROFILE_IMAGE"/>
				<TextInput source="user.fullName" label="NAME" validate={[required()]}/>
				<TextInput
					type="tel"
					source="user.phone.phone" label="PHONE" validate={[required()]}
				    format={formatPhoneNumber} parse={formatPhoneNumber} sx={{ direction: 'ltr !important' }}/>
				<TextInput source="user.email" label="EMAIL"/>
				<BooleanInput source="isOwner" label="IS_OWNER" validate={[required()]}/>
			</SimpleForm>
		</Create>
	);
};
