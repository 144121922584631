import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import Pagination from '../components/Pagination';

import {
	AutocompleteArrayInput,
	AutocompleteInput,
	BooleanInput,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	ReferenceArrayInput,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	TextInput,
	TopToolbar,
	usePermissions, useTranslate,
} from 'react-admin';
import { DeliveryList } from './DeliveryList';
import { DeliveryDatagrid } from './DeliveryDatagrid';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Delivery } from './Delivery';
import { useLocation } from 'react-router-dom';
import { EDeliveryPackageType, EDeliveryStatus, EUserType } from '../enums';
import { LOCAL_STORAGE_BUSINESS_ID } from '../providers/constants';

export * from './Delivery';
export * from './DeliveryCreate';
export * from './DeliveryDatagrid';
export * from './DeliveryEdit';
export * from './DeliveryList';
export * from './DeliveryShow';

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'business.name', header: 'עסק' },
	{ key: 'location.name', header: 'לקוח' },
	{ key: 'createdBy.fullName', header: 'נוצר ע״י' },
	{ key: 'status', header: 'סטטוס' },
	{ key: 'lastStatusUpdateDate', header: 'תאריך עדכון סטטוס אחרון' },
	{ key: 'pickUpAddress.addressStr', header: 'איסוף מ-' },
	{ key: 'dropOffAddress.addressStr', header: 'כתובת נמען' },
	{ key: 'date', header: 'תאריך' },
	{ key: 'isUrgent', header: 'דחוף' },
	{ key: 'isLegal', header: 'משפטי' },
	{ key: 'price', header: 'מחיר' },
	{ key: 'type', header: 'סוג' },
	{ key: 'waitingTime', header: 'זמן המתנה' },
	{ key: 'numberOfChecks', header: 'מס׳ צ׳קים שנאספו' },
	{ key: 'failReason', header: 'סיבת כישלון' },
	{ key: 'pickUpRemarks', header: 'הערות איסוף של שליח' },
];

export const DeliveriesData = (props: ListProps): React.ReactElement => {
	const location = useLocation();
	const { permissions } = usePermissions();
	const translate = useTranslate();
	const [defaultFilterValues, setDefaultFilterValues] = useState<any | undefined>({});
	let initialBusinessId = useRef<string>();
	let initialCourierId = useRef<string>();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const businessId = searchParams.get('businessId');
		const courierId = searchParams.get('courierId');
		let isDefaultFilterValues = false;

		if (businessId) {
			setDefaultFilterValues({ business: parseInt(businessId) });
			initialBusinessId.current = businessId;
			isDefaultFilterValues = true;
		}

		if (courierId) {
			setDefaultFilterValues({ courier: parseInt(courierId) });
			initialCourierId.current = courierId;
			isDefaultFilterValues = true;
		}

		if (!isDefaultFilterValues) {
			setDefaultFilterValues(undefined);
		}
	}, [location.search]);

	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Delivery) => entity;

	const deliveriesFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn resettable/>,
		[EUserType.Admin, EUserType.SubAdmin].includes(permissions) && !initialBusinessId.current ?
			<ReferenceInput
				label="BUSINESS"
				source="business" reference="businesses"
				alwaysOn={false}
				initialValue={initialBusinessId.current ? { id: initialBusinessId.current } : undefined}
			>
				<AutocompleteInput
					label="BUSINESS" optionText="name"/>
			</ReferenceInput> : null,
		<ReferenceInput
			label="LOCATION"
			source="location" reference="locations"
			alwaysOn={false}
		>
			<AutocompleteInput
				label="LOCATION" optionText="name"/>
		</ReferenceInput>,
		<ReferenceInput
			label="CREATED_BY"
			source="eq_createdBy" reference="users"
			filter={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? undefined : { 'employee.businessId': parseInt((localStorage.getItem(LOCAL_STORAGE_BUSINESS_ID) ?? -1).toString()) }}
			alwaysOn={false}
		>
			<AutocompleteInput
				label="CREATED_BY" optionText="fullName"/>
		</ReferenceInput>,
		<ReferenceInput
			label="CREATED_BY_COURIER"
			source="enum_createdBy" reference="users"
			filter={{ eq_type: EUserType.Courier }}
			alwaysOn={false}
		>
			<AutocompleteInput
				label="CREATED_BY_COURIER" optionText="fullName"/>
		</ReferenceInput>,
		<ReferenceInput
			label="DUPLICATED_BY_COURIER"
			source="eq_relatedDelivery___courier" reference="couriers"
			alwaysOn={false}
		>
			<AutocompleteInput
				label="DUPLICATED_BY_COURIER" optionText="user.fullName"/>
		</ReferenceInput>,
		<SelectArrayInput source="in_status" label="STATUS"
		                  choices={Object.keys(EDeliveryStatus).map(key => ({
			                  id: key,
			                  name: EDeliveryStatus[key]
		                  }))} alwaysOn={false}/>,
		<DateInput source="from_lastStatusUpdateDate" label="FROM_LAST_STATUS_UPDATE_DATE"/>,
		<DateInput source="to_lastStatusUpdateDate" label="TO_LAST_STATUS_UPDATE_DATE"/>,
		<ReferenceArrayInput
			label="CITY"
			source="in_pickUpCity" reference="cities"
			alwaysOn={false}
		>
			<AutocompleteArrayInput
				label="CITY" optionText="name"/>
		</ReferenceArrayInput>,
		<DateInput source="from_date" label="FROM_DATE"/>,
		<DateInput source="to_date" label="TO_DATE"/>,
		<BooleanInput
			label="IS_URGENT"
			source="isUrgent"
			alwaysOn={false}
		/>,
		<ReferenceInput
			label="COURIER"
			source="courier" reference="couriers"
			alwaysOn={false}
		>
			<AutocompleteInput
				label="COURIER" optionText="user.fullName"/>
		</ReferenceInput>,
		<SelectInput source="type" label="TYPE"
		             choices={Object.keys(EDeliveryPackageType).map(key => ({
			             id: key,
			             name: EDeliveryPackageType[key]
		             }))}/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,

	].filter(v => v) as JSX.Element[];

	const clearFilters = () => {
		localStorage.removeItem('RaStore.deliveries.listParams');
		const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
		window.location.href = url;
	};

	return (
		<Fragment>
			<List
				exporter={generateExporter('קריאות', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<Button onClick={() => clearFilters()}>
							{translate('CLEAR_FILTERS')}
						</Button>
						<FilterButton/>
						<ExportButton/>
						<CreateButton/>
					</TopToolbar>
				}
				{...props}
				filter={defaultFilterValues}
				filters={deliveriesFilter}
				bulkActionButtons={false}
				title="DELIVERIES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <DeliveryList/> : <DeliveryDatagrid/>}
			</List>
		</Fragment>
	);
};
