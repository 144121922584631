import * as React from 'react';
import { Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import { PhoneField } from '../components/PhoneField';

export const VerificationShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" label="#"/>
				<TextField source="verificationCode" label="VERIFICATION_CODE"/>
				<PhoneField source="phone" label="PHONE"/>
			</SimpleShowLayout>
		</Show>
	);
};
