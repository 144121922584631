import * as React from 'react';
import { ChipField, EditButton, ReferenceArrayField, ShowButton, SingleFieldList, useListContext } from 'react-admin';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

const locationsGroupStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {
		paddingTop: 0
	},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const LocationsGroupList = () => {
	const classes = locationsGroupStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="locations-groups"
										record={entity}
									/>
									<EditButton
										resource="locations-groups"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
					<CardContent sx={classes.cardContent}>
						<ReferenceArrayField record={entity} source="locationsIds" label="LOCATIONS"
						                     reference="locations">
							<SingleFieldList>
								<ChipField source="name"/>
							</SingleFieldList>
						</ReferenceArrayField>
					</CardContent>
				</Card>
			))}
		</div>
	);
};
