import * as React from 'react';
import { LocationsGroup } from './LocationsGroup';
import { Loading, ReferenceArrayField, useRecordContext, useTranslate } from 'react-admin';
import './LocationsGroupProfile.scss';
import { LocationList } from '../location';

export const LocationsGroupProfile = () => {
	const locationsGroup: LocationsGroup = useRecordContext();
	const translator = useTranslate();

	if (locationsGroup)
		return <div className="locations-group-profile">
			<div className="locations-group-profile-name">
				<span>{locationsGroup.name}</span>
			</div>
			<div className="locations-group-profile-employees-title">{translator('LOCATIONS')}</div>
			<ReferenceArrayField source="locationsIds" reference="locations">
				<LocationList/>
			</ReferenceArrayField>
		</div>;

	return <Loading/>;
};
