import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	TextInput,
	TopToolbar,
} from 'react-admin';
import { CourierList } from './CourierList';
import { CourierDatagrid } from './CourierDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Courier } from './Courier';

export * from './Courier';
export * from './CourierCreate';
export * from './CourierDatagrid';
export * from './CourierEdit';
export * from './CourierList';
export * from './CourierShow';

const CouriersBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'profileImageUrl', header: 'תמונת פרופיל' },
	{ key: 'user.fullName', header: 'שם מלא' },
	{ key: 'user.phone.phone', header: 'טלפון' },
	{ key: 'user.email', header: 'אימייל' },
	{ key: 'currentNumberOfPackages', header: 'מספר קריאות' },
];

export const CouriersData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Courier) => entity;

	const couriersFilter = [
		<TextInput source="q" label="SEARCH" alwaysOn resettable/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('שליחים', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<ExportButton/>
						<CreateButton/>
					</TopToolbar>
				}
				{...props}
				filters={couriersFilter}
				bulkActionButtons={<CouriersBulkActionButtons/>}
				title="COURIERS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <CourierList/> : <CourierDatagrid/>}
			</List>
		</Fragment>
	);
};
