import * as React from 'react';
import { useRecordContext, useTranslate, } from 'react-admin';
import { Location } from './Location';

export const LocationTitle = () => {
	const translator = useTranslate();
	const location: Location = useRecordContext();
	console.log(location);

	return <span>{translator('LOCATION')} {location ? ` - ${location.name}` : ''}</span>;
};

