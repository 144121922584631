import * as React from 'react';
import { Datagrid, EmailField, ReferenceField, TextField, usePermissions } from 'react-admin';
import './LocationDatagrid.scss';
import { PhoneField } from '../components/PhoneField';
import { EUserType } from '../enums';

export const LocationDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="edit" className="locations-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<TextField source="name" label="NAME"/>
			<TextField source="address.addressStr" label="ADDRESS" sortable={false}/>
			<TextField source="contactName" label="CONTACT_NAME"/>
			<PhoneField source="contactPhone" label="CONTACT_PHONE"/>
			<EmailField source="contactEmail" label="CONTACT_EMAIL"/>
			<TextField source="contactNameB" label="CONTACT_NAME_B"/>
			<PhoneField source="contactPhoneB" label="CONTACT_PHONE_B"/>
			<EmailField source="contactEmailB" label="CONTACT_EMAIL_B"/>
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <ReferenceField source="businessId" reference="businesses" label="BUSINESS" sortable={false}>
                    <TextField source="name"/>
                </ReferenceField>
			}
			<TextField source="remarks" label="REMARKS"/>
		</Datagrid>
	);
};
