import * as React from 'react';
import { FileField, FileInput, FunctionField, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { API_URL } from '../providers/constants';
import { httpClient } from '../providers/http_client';

export const UploadFile = ({ record, source, multiple, ...props }: any) => {
	const recordByContext = useRecordContext();
	record = record ?? recordByContext;
	const form = useFormContext();

	const onFileChange = async (files: any) => {
		if (files) {
			const initialValue = form.getValues(source);
			const formData = new FormData();

			if (multiple && files.length) {
				for (let i = 0; i < files.length; i++) {
					formData.append(`files`, files[i]);
				}
			} else {
				formData.append('file', files);
			}

			const { json } = await httpClient(`${API_URL}/files/upload/${multiple ? 'multiple' : 'single'}`, {
				method: 'POST',
				headers: new Headers({ 'ContentType': 'multipart/form-data' }),
				body: formData
			});

			setTimeout(() => {
				form.setValue(source, multiple ? (initialValue ?? []).concat(json.data) : json.data);
			}, 200);
		}
	};

	return <FileInput {...props}
	                  source={source}
	                  multiple={multiple}
	                  onChange={onFileChange}
	>
		<FunctionField render={(record) => {
			console.log(record);
			const file = { src: record, title: record?.title ?? record?.split('/').pop() };

			return <FileField record={{ file }} source="file" src={file.src} title={file.title}/>;
		}}/>
	</FileInput>;
};
