import * as React from 'react';
import { Show, ShowProps, } from 'react-admin';
import { BusinessProfile } from './BusinessProfile';
import { BusinessTitle } from './BusinessTitle';

export const BusinessShow = (props: ShowProps): React.ReactElement => {
	return (
		<Show {...props} actions={false} title={<BusinessTitle/>}>
			<BusinessProfile/>
		</Show>
	);
};
