import React, { useCallback, useEffect, useState } from 'react';
import {
	AutocompleteInput,
	DateInput,
	Filter,
	ListContextProvider,
	ReferenceInput,
	TextInput,
	usePermissions,
	useTranslate
} from 'react-admin';
import { Reports as IReports } from '../../interfaces/reports.interface';
import SegmentationChart from './SegmentationChart';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { Spacer, VerticalSpacer } from '../Spacers';
import { formatQueryParams } from '../../util/Formatters';
import { serialize } from '../../util/Serialize';
import './Reports.scss';
import get from 'lodash-es/get';
import { httpClient } from '../../providers/http_client';
import { API_URL } from '../../providers/constants';
import { EUserType } from '../../enums';

const styles = {
	flex: { display: 'flex' },
	flexColumn: { display: 'flex', flexDirection: 'column' },
	leftCol: { flex: 1, marginRight: '0.5em' },
	rightCol: { flex: 1, marginLeft: '0.5em' },
	singleCol: { marginTop: '1em', marginBottom: '1em' }
};

export const Reports = () => {
	const { permissions } = usePermissions();
	const translator = useTranslate();
	const [reports, setReports] = useState<IReports>();

	const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const [filterValues, setFilterValues] = useState({
		enum_business: undefined,
		enum_courier: undefined,
		from_lastStatusUpdateDate: undefined,
		to_lastStatusUpdateDate: undefined,
		'pickUpAddressAddressstr': undefined,
	});

	const setFilters = (filters) => {
		const filter = prepareFilter(filters);

		setFilterValues(filter);
		setTimeout(() => fetchReports(filter), 200);
	};

	const prepareFilter = (filters) => {
		const filterClone = { ...filters };
		Object.keys(filterClone).forEach(k => filters[k] = (filters[k] !== '' && filters[k] !== null) ? filters[k] : undefined);

		return filterClone;
	};

	const fetchReports = useCallback(async (filters = filterValues) => {
		const properties = formatQueryParams({
			filter: filters,
			pagination: { perPage: 9999, page: 1 },
			sort: { field: 'lastStatusUpdateDate', order: 'DESC' }
		});
		const { json } = await httpClient(`${API_URL}/admin/reports?${serialize(properties)}`);

		if (json.data) {
			json.data.segmentationByType.forEach((currSegment) => {
				if (currSegment.name === 'PickUpDropOff') {
					currSegment.name = 'DELIVERY_TYPE_PICKUP_WITH_RETURN'
				} else {
					currSegment.name = 'DELIVERY_TYPE_PICKUP'
				}
			})
		}

		setReports(json.data);
	}, [filterValues]);

	useEffect(() => {
		const filter = prepareFilter(filterValues);

		fetchReports(filter);
	}, [fetchReports, filterValues]);

	return (
		<>
			<div>
				<ListContextProvider value={{ setFilters, filterValues } as any}>
					<Filter>
						{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                            <ReferenceInput value={filterValues.enum_business}
                                            label="BUSINESS"
                                            source="enum_business" reference="businesses"
                                            alwaysOn
                            >
                                <AutocompleteInput
                                    label="BUSINESS" optionText="name"/>
                            </ReferenceInput>}
						{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                            <ReferenceInput value={filterValues.enum_courier}
                                            label="COURIER"
                                            source="enum_courier" reference="couriers"
                                            alwaysOn
                            >
                                <AutocompleteInput
                                    label="COURIER" optionText="user.fullName"/>
                            </ReferenceInput>}
						<DateInput value={filterValues.from_lastStatusUpdateDate}
						           source="from_lastStatusUpdateDate"
						           label="FROM_LAST_STATUS_UPDATE_DATE" alwaysOn/>
						<DateInput value={filterValues.to_lastStatusUpdateDate}
						           source="to_lastStatusUpdateDate"
						           label="TO_LAST_STATUS_UPDATE_DATE" alwaysOn/>
						<TextInput value={get(filterValues, 'pickUpAddressAddressstr')}
						           source="pickUpAddressAddressstr"
						           label="PICK_UP_ADDRESS" alwaysOn/>
					</Filter>
				</ListContextProvider>
				<Button
					style={{ width: 'fit-content', marginTop: '2re' }}
					onClick={() => setFilters({
						enum_business: undefined,
						enum_courier: undefined,
						from_lastStatusUpdateDate: undefined,
						to_lastStatusUpdateDate: undefined,
						'pickUpAddressAddressstr': undefined,
					})}>{translator('CLEAR_FILTERS')}</Button>
			</div>
			<div style={styles.singleCol}>
				<div style={isSmall ? styles.singleCol : styles.flex}>
					<div style={styles.rightCol}>
						<SegmentationChart segmentation={reports?.segmentationByStatus}
						                   header={'SEGMENTATION_BY_STATUS'}/>
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.leftCol}>
						<SegmentationChart segmentation={reports?.segmentationByType}
						                   header={'SEGMENTATION_BY_TYPE'}/>
						{/*<VerticalSpacer/>*/}
					</div>
					{isSmall ? <VerticalSpacer/> : <Spacer/>}
					<div style={styles.leftCol}>
						<SegmentationChart segmentation={reports?.segmentationByFailReason}
						                   header={'SEGMENTATION_BY_FAIL_REASON'}/>
						{/*<VerticalSpacer/>*/}
					</div>
					<div style={styles.leftCol}>
						<SegmentationChart segmentation={reports?.segmentationByCreationWay}
						                   header={'SEGMENTATION_BY_CREATION_WAY'}/>
						{/*<VerticalSpacer/>*/}
					</div>
				</div>
				<VerticalSpacer/>
				{/*<Button variant="contained"*/}
				{/*        onClick={() => navigate(`/deliveries`,)}><span>{translator('SEE_FULL_REPORT')}</span></Button>*/}
			</div>
		</>
	);
};
