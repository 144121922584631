// ScrollToBottomButton.js

import React, { useEffect, useState } from 'react';

const ScrollToBottomButton = () => {
	const [isVisible, setIsVisible] = useState(false);

// Check the scroll position and update the visibility
	useEffect(() => {
		const toggleVisibility = () => {
			if (window.scrollY > 200) { // Adjust 100 to when you want the button to show
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener('scroll', toggleVisibility);

		return () => window.removeEventListener('scroll', toggleVisibility);
	}, []);

	const scrollToBottom = () => {
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: 'smooth',
		});
	};

	return (
		isVisible ? (<button
			onClick={scrollToBottom}
			style={{
				position: 'fixed',
				bottom: '60px',
				right: '20px',
				borderRadius: '50%',
				backgroundColor: '#007bff', // Bootstrap primary blue
				color: 'white',
				border: 'none',
				width: '50px',
				height: '50px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: '9999',
				cursor: 'pointer',
				boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
			}}
			aria-label="Scroll to bottom"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
				<path fillRule="evenodd"
				      d="M8 12a.5.5 0 0 1-.354-.146l-3-3a.5.5 0 1 1 .708-.708L8 10.793l2.646-2.647a.5.5 0 0 1 .708.708l-3 3A.5.5 0 0 1 8 12Z"/>
				<path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-1 0v-9A.5.5 0 0 1 8 2Z"/>
			</svg>
		</button>) : (<div></div>)
	);
}


export default ScrollToBottomButton;
