import React from 'react';
import { FunctionField } from 'react-admin';
import _get from 'lodash-es/get';

export const PriceField: any = ({ ...props }) => {
	return (
		<FunctionField {...props}
		               render={(record, source) => {
			               const value = _get(record, source);

			               try {
				               const price = parseFloat(value);

				               if (isNaN(price)) {
					               return '';
				               }

				               return price.toLocaleString(undefined, {
					               style: 'currency',
					               currency: 'ILS',
					               minimumFractionDigits: 0,
					               maximumFractionDigits: 0,
				               });
			               } catch (e) {
				               return value;
			               }
		               }}
		/>
	);
};

PriceField.defaultProps = {
	addLabel: true,
};
