import * as React from 'react';
import { Fragment, useCallback, useEffect } from 'react';
import Pagination from '../components/Pagination';
import {
	AutocompleteInput,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	ReferenceInput,
	SelectInput,
	TopToolbar,
	usePermissions
} from 'react-admin';
import { NotificationList } from './NotificationList';
import { NotificationDatagrid } from './NotificationDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { Notification } from './Notification';
import { ENotificationLabel, EUserType } from '../enums';
import { API_URL, LOCAL_STORAGE_BUSINESS_ID } from '../providers/constants';
import { httpClient } from '../providers/http_client';

export * from './Notification';
export * from './NotificationDatagrid';
export * from './NotificationList';

export const exportFields: ExportField[] = [
	{ key: 'price', header: 'מחיר' },
	{ key: 'title', header: 'טייטל' }
];

export const NotificationsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: Notification) => entity;
	const { permissions } = usePermissions();

	const businessId = localStorage.getItem(LOCAL_STORAGE_BUSINESS_ID);

	const notificationsFilter = [
		<ReferenceInput source={'eq_delivery___createdById'} reference="employees"
		                filter={businessId ? {
			                'businessId': businessId,
		                } : undefined}
		                alwaysOn>
			<AutocompleteInput label="EMPLOYEES" optionText="user.fullName" optionValue="userId"/>
		</ReferenceInput>,
		<SelectInput source="eq_label" label="NOTIFICATION_LABEL"
		             choices={Object.keys(ENotificationLabel).map(key => ({
			             id: key,
			             name: `NOTIFICATION_LABELS.${ENotificationLabel[key]}`
		             }))} alwaysOn/>,
		<DateInput source="from__createdAt" label="FROM_CREATED_AT" alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT" alwaysOn/>
	];

	const markNotificationsAsRead = useCallback(async () => {
		await httpClient(`${API_URL}/notifications/read`, { method: 'POST' });
	}, []);

	useEffect(() => {
		markNotificationsAsRead();
	}, [markNotificationsAsRead]);

	return (
		<Fragment>
			<List
				exporter={generateExporter('מוצרים', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<ExportButton/>
						{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) && <CreateButton/>}
					</TopToolbar>
				}
				{...props}
				filters={notificationsFilter}
				bulkActionButtons={false}
				hasCreate={[EUserType.Admin, EUserType.SubAdmin].includes(permissions)}
				title="NOTIFICATIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <NotificationList/> : <NotificationDatagrid/>}
			</List>
		</Fragment>
	);
};
