import * as React from 'react';
import { Business } from './Business';
import { ArrayField, Loading, TextField, useRecordContext, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { EmployeeList } from '../employee';
import { Button } from '@mui/material';
import './BusinessProfile.scss';

export const BusinessProfile = () => {
	const business: Business = useRecordContext();
	const translator = useTranslate();
	const navigate = useNavigate();

	if (business)
		return <div className="business-profile">
			<div className="business-profile-image">
				<img src={business.profileImageUrl || '/assets/profile-image-placeholder.svg'}
				     alt={translator('EMPLOYEE')}/>
			</div>
			<div className="business-profile-name">
				<span>{business.name}</span>
			</div>
			<TextField source="deliveryPrice" label="DELIVERY_PRICE"/>
			<div className="business-profile-employees-title">{translator('EMPLOYEES')}</div>
			<ArrayField source="employees" className="business-profile-employees">
				<EmployeeList businessId={business.id}/>
			</ArrayField>
			<div className="business-profile-ctas">
				<Button variant="outlined"
				        onClick={() => navigate(`/locations?businessId=${business.id}`)}><span>{translator('SHOW_LOCATIONS')}</span></Button>
				<Button variant="outlined"
				        onClick={() => navigate(`/employees/create?businessId=${business.id}`, {})}><span>{translator('ADD_EMPLOYEE')}</span></Button>
			</div>
		</div>;

	return <Loading/>;
};
