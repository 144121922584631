import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import { DateInput, List, ListProps } from 'react-admin';
import { VerificationList } from './VerificationList';
import { VerificationDatagrid } from './VerificationDatagrid';
import { Theme, useMediaQuery } from '@mui/material';

export * from './Verification';
export * from './VerificationDatagrid';
export * from './VerificationList';
export * from './VerificationShow';

export const VerificationsData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

	const verificationsFilter = [
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"
		           alwaysOn/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"
		           alwaysOn/>
	];

	return (
		<Fragment>
			<List
				exporter={false}
				{...props}
				filters={verificationsFilter}
				title="VERIFICATIONS"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <VerificationList/> : <VerificationDatagrid/>}
			</List>
		</Fragment>
	);
};
