import * as React from 'react';
import { Datagrid, DateField, NumberField, TextField } from 'react-admin';
import './NotificationDatagrid.scss';
import { Notification } from './Notification';
import { ENotificationLabel } from '../enums';

export const NotificationDatagrid = (): React.ReactElement => {
	return (
		<Datagrid className="notifications-table" optimized
		          rowClick={(id, basePath, record) => {
			          if ((record as Notification)?.deliveryId) {
				          return `/deliveries/${record.deliveryId}/show`;
			          } else {
				          return false;
			          }
		          }}
		          rowStyle={(row: Notification) => ({
			          borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb',
			          backgroundColor: row.label === ENotificationLabel.Update ? '#FFD580' :
				          [ENotificationLabel.Attached, ENotificationLabel.New].includes(row.label) ? '#ADD8E6' :
				          [ENotificationLabel.Done].includes(row.label) ? '#90EE90' :
				          [ENotificationLabel.Urgent].includes(row.label) ? '#f65654' :
					          [ENotificationLabel.Deleted, ENotificationLabel.Failed].includes(row.label) ? '#FFCCCB' :
						          'unset'
		          })}>
			<NumberField source="id" label="#"/>
			<DateField locales="he-IL" label="CREATED_AT" source="_createdAt" showTime/>
			<TextField source="content" label="CONTENT"/>
			{/*<ReferenceField source="createdById" reference="users" label="CREATED_BY">*/}
			{/*	<ReferenceField reference="employees" source="employeeId">*/}
			{/*		<TextField source="name"/>*/}
			{/*	</ReferenceField>*/}
			{/*	<ReferenceField reference="couriers" source="courierId">*/}
			{/*		<TextField source="name"/>*/}
			{/*	</ReferenceField>*/}
			{/*	<TextField source="fullName"/>*/}
			{/*</ReferenceField>*/}
		</Datagrid>
	);
};
