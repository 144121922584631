import * as React from 'react';
import { Edit, EditProps, required, SimpleForm, TextInput, } from 'react-admin';

export const CityEdit = (props: EditProps): React.ReactElement => {
	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm sx={{ '& > *': { width: '100%' } }}>
				<TextInput source="name" label="NAME" validate={[required()]}/>
			</SimpleForm>
		</Edit>
	);
};
