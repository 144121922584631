import * as React from 'react';
import { EditButton, ShowButton, useListContext } from 'react-admin';
import { Box, Card, CardHeader } from '@mui/material';

const cityStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardHeader: {
		padding: '0 !important'
	},
};

export const CityList = () => {
	const classes = cityStyle;
	const { data } = useListContext();

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						sx={classes.cardHeader}
						title={
							<Box sx={classes.cardTitleContent}>
								<span>{entity.name}</span>
								<div>
									<ShowButton
										resource="cities"
										record={entity}
									/>
									<EditButton
										resource="cities"
										record={entity}
									/>
								</div>
							</Box>
						}
					/>
				</Card>
			))}
		</div>
	);
};
