import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'ra-core';
import TextField from '@mui/material/TextField';
import { useTranslate } from 'react-admin';
import './TimeInput.scss';

const Picker = ({ defaultValue, ...fieldProps }: { options, label, source, defaultValue?: string }) => {
	const translator = useTranslate();
	const {
		options,
		label,
		source,
	} = fieldProps;

	const input = useInput({ source, defaultValue });

	const handleChange = useCallback(event => {
		try {
			input.field.onChange(event);
		} catch (e) {

		}
	}, [input]);

	return (
		<TextField
			{...options}
			className="time-input"
			label={translator(label)}
			defaultValue={input.field?.value}
			type="time"
			InputLabelProps={{
				shrink: true
			}}
			inputProps={{ step: 900 }}
			onChange={handleChange}
		/>
	);
};

Picker.propTypes = {
	input: PropTypes.object,
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	options: PropTypes.object,
	resource: PropTypes.string,
	source: PropTypes.string,
	labelTime: PropTypes.string,
	className: PropTypes.string
};

Picker.defaultProps = {
	input: {},
	isRequired: false,
	meta: { touched: false, error: false },
	options: {},
	resource: '',
	source: '',
	labelTime: '',
	className: ''
};

export const TimeInput = props => <Picker {...props} />;
