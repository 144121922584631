import React, { useCallback, useEffect, useState } from 'react';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import TodayIcon from '@mui/icons-material/Today';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Button, useDataProvider, usePermissions, useRedirect, useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import { Stats } from '../../interfaces/stats.interface';
import { subDays } from 'date-fns';
import { Spacer, VerticalSpacer } from '../Spacers';
import { API_URL } from '../../providers/constants';
import { httpClient } from '../../providers/http_client';
import { EUserType } from '../../enums';
import DeliveriesBarChart from "./DeliveriesBarChart";
import { Delivery } from "../../delivery";

const styles = {
	flex: { display: 'flex' },
	flexColumn: { display: 'flex', flexDirection: 'column' },
	leftCol: { flex: 1, marginRight: '0.5em' },
	rightCol: { flex: 1, marginLeft: '0.5em' },
	singleCol: { marginTop: '1em', marginBottom: '1em' }
};

interface DeliveryData {
	date: string;
	deliveries: number;
}

export const Dashboard = () => {
	const dataProvider = useDataProvider();
	const translate = useTranslate();
	const { permissions } = usePermissions();
	const redirect = useRedirect();

	const [deliveriesGraph, setDeliveriesGraph] = useState<DeliveryData[]>([]);
	const [stats, setStats] = useState<Stats>();

	// const isXSmall = useMediaQuery((theme: Theme) =>
	// 	theme.breakpoints.down('xs')
	// );

	if (permissions && permissions === EUserType.Employee) {
		redirect('deliveries');
	}

	const formatDateToDDMM = (date) => {
		const parts = date.split('-'); // Split the date string into [YYYY, MM, DD]
		return `${parts[2]}/${parts[1]}`; // Rearrange the parts to 'DD/MM'
	};

	const fetchDeliveries = useCallback(async () => {
		const { data } = await dataProvider.getList<Delivery>(
			'deliveries',
			{
				filter: {
					'from__createdAt': subDays(new Date(), 7),
					'to__createdAt': new Date(),
				},
				sort: { field: '_createdAt', order: 'DESC' },
				pagination: { page: 1, perPage: 9999 }
			}
		);

		console.log(data);

		function formatDateToYYYYMMDD(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed, pad with leading zero
			const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero

			return `${year}-${month}-${day}`;
		}

		// First, group deliveries by date
		const groupedByDate = data.reduce((acc, delivery) => {
			const date = formatDateToYYYYMMDD(new Date(delivery._createdAt)); // Assuming this is in 'YYYY-MM-DD' format
			if (!acc[date]) {
				acc[date] = 0; // Initialize this date if it doesn't exist in the accumulator
			}
			acc[date] += 1; // Increment the count for this date
			return acc;
		}, {});

		// Then, map the aggregated data into the desired format
		const deliveriesData = Object.keys(groupedByDate).map(date => {
			const shortDate = formatDateToDDMM(date); // Convert 'YYYY-MM-DD' to 'MM/DD'
			return {
				date: shortDate,
				deliveries: groupedByDate[date],
			};
		});

		// Optionally, sort by date if needed
		deliveriesData.sort((a, b) => {
			// Convert 'DD/MM' to 'MM/DD' to make it directly usable by Date constructor
			const dateA = a.date.split('/').reverse().join('/');
			const dateB = b.date.split('/').reverse().join('/');

			return new Date(dateA).getTime() - new Date(dateB).getTime();
		});

		setDeliveriesGraph(deliveriesData);
	}, [dataProvider]);

	const fetchStats = useCallback(async () => {
		const { json } = await httpClient(`${API_URL}/admin/stats`);

		setStats(json.data);
	}, []);

	useEffect(() => {
		fetchDeliveries();
	}, [fetchDeliveries]);

	useEffect(() => {
		fetchStats();
	}, [fetchStats]);

	const handleLocalRefresh = () => {
		const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
		window.location.href = url;
	};

	return (
		<div style={styles.leftCol}>
			<div style={styles.flex}>
				<CardWithIcon
					to={`/deliveries?${encodeURI(`displayedFilters={"in_status":true}&filter={"in_status":["Done","PickedUp","Failed"]}&sort=lastStatusUpdateDate`)}`}
					icon={TodayIcon} title={translate('TOTAL_DELIVERIES_DONE_TODAY')}
					subtitle={stats?.totalDeliveriesToday?.toLocaleString() || '---'}/>
				<Spacer/>
				<CardWithIcon
					to={`/deliveries?${encodeURI(`displayedFilters={"in_status":true}&filter={"in_status":"New"}`)}`}
					icon={ImportContactsIcon} title={translate('TOTAL_OPEN_DELIVERIES')}
					subtitle={stats?.totalOpenDeliveries?.toLocaleString() || '---'}/>
			</div>
			<VerticalSpacer/>
			<div style={styles.flex}>
				<CardWithIcon
					to={`/deliveries?${encodeURI(`displayedFilters={"in_status":true}&filter={"in_status":"AssignToCourier"}`)}`}
					icon={AssignmentIndIcon}
					title={translate('TOTAL_COURIER_ASSIGNED_DELIVERIES')}
					subtitle={stats?.totalCourierAssignedDeliveries?.toLocaleString() || '---'}/>
				<Spacer/>
				<CardWithIcon
					to={`/deliveries?${encodeURI(`displayedFilters={"isUrgent":true}&filter={"isUrgent":true}`)}`}
					icon={WhatshotIcon} title={translate('TOTAL_OPEN_URGENT_DELIVERIES')}
					subtitle={stats?.totalOpenUrgentDeliveries?.toLocaleString() || '---'}/>
			</div>
			<VerticalSpacer/>
			<div style={styles.flex}>
				<Button variant="contained" onClick={handleLocalRefresh}>
					<span>{translate('REFRESH')}</span>
				</Button>
			</div>
			<VerticalSpacer/>
			<h2>כמות משלוחים לפי ימים (תאריך יצירה)</h2>

			<DeliveriesBarChart data={deliveriesGraph} />
		</div>
	);
};
