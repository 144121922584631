import * as React from 'react';
import { useState } from 'react';
import { ImageInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { httpClient } from '../providers/http_client';
import { API_URL } from '../providers/constants';
import { get as _get } from 'lodash';
import { CircularProgress } from '@mui/material';

export const MultipleImagesInput = ({ record, source, ...props }: any) => {
	const recordByContext = useRecordContext();
	record = record ?? recordByContext;
	const form = useFormContext();
	const [images, setImages] = useState<File[]>(null as any);
	const [isLoading, setIsLoading] = useState(false);

	const onFileChange = async (files: any) => {
		if (files) {
			const formData = new FormData();

			if (files.length) {
				for (let i = 0; i < files.length; i++) {
					formData.append(`files`, files[i]);
					setImages(files);
				}
			}

			console.log(files);

			setIsLoading(true);
			const { json } = await httpClient(`${API_URL}/files/upload/multiple`, {
				method: 'POST',
				headers: new Headers({ 'ContentType': 'multipart/form-data' }),
				body: formData
			});
			setIsLoading(false);

			form.setValue(source, json.data);
		}
	};

	let imagesUrls: string[] = [];

	if (images) {
		imagesUrls = (images ?? []).map(image => URL.createObjectURL(image));
	} else {
		imagesUrls = _get(record, source);
	}

	return <>
		<ImageInput {...props}
		            source={source}
		            multiple={true}
		            onChange={onFileChange}
		>
			<></>
		</ImageInput>
		{
			isLoading ?
				<CircularProgress/> :
				(<div className="previews">
					{(imagesUrls ?? [])?.map(imageUrl => <img src={imageUrl} alt=""/>)}
				</div>)
		}
	</>;
};
