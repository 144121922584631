import * as React from 'react';
import { Fragment } from 'react';
import Pagination from '../components/Pagination';
import {
	BulkActionProps,
	BulkDeleteButton,
	CreateButton,
	DateInput,
	ExportButton,
	FilterButton,
	List,
	ListProps,
	TopToolbar,
} from 'react-admin';
import { CityList } from './CityList';
import { CityDatagrid } from './CityDatagrid';
import { Theme, useMediaQuery } from '@mui/material';
import { ExportField, generateExporter } from '../util/Exporter';
import { City } from './City';

export * from './City';
export * from './CityCreate';
export * from './CityDatagrid';
export * from './CityEdit';
export * from './CityList';
export * from './CityShow';

const CitiesBulkActionButtons = (props: BulkActionProps) => (
	<Fragment>
		<BulkDeleteButton {...props} mutationMode="pessimistic"/>
	</Fragment>
);

export const exportFields: ExportField[] = [
	{ key: 'id', header: '#' },
	{ key: 'name', header: 'שם העיר' },
];

export const CitiesData = (props: ListProps): React.ReactElement => {
	const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
	const exportDataMapper = (entity: City) => entity;

	const citiesFilter = [
		<DateInput source="from__createdAt" label="FROM_CREATED_AT"/>,
		<DateInput source="to__createdAt" label="TO_CREATED_AT"/>,
	];

	return (
		<Fragment>
			<List
				exporter={generateExporter('ערים', exportFields, exportDataMapper)}
				actions={
					<TopToolbar>
						<FilterButton/>
						<ExportButton/>
						<CreateButton/>
					</TopToolbar>
				}
				{...props}
				filters={citiesFilter}
				bulkActionButtons={<CitiesBulkActionButtons/>}
				title="CITIES"
				sort={{ field: '_createdAt', order: 'DESC' }}
				perPage={20}
				pagination={<Pagination/>}
			>
				{isXSmall ? <CityList/> : <CityDatagrid/>}
			</List>
		</Fragment>
	);
};
