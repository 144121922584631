export const hebrewMessages = {
	PROFILE_IMAGE: 'תמונת פרופיל',
	NAME: 'שם',
	CONTENT: 'תוכן',
	PRICE: 'מחיר',
	SEARCH: 'חיפוש',
	IS_READ: 'נצפה',
	DATE: 'תאריך',
	DASHBOARD_WELCOME: 'ברוכים הבאים לפאנל הניהול',
	DASHBOARD: 'דשבורד',
	MONTHLY_REVENUE: 'הכנסה חודשית',
	NEW_ORDERS: 'הזמנות חדשות',
	MONTH_HISTORY: 'החודש האחרון',
	FROM_DATE: 'מתאריך',
	TO_DATE: 'עד תאריך',
	PHONE_NUMBER: 'מספר טלפון',
	OTP: 'קוד אימות',
	OTP_LENGTH_ERROR: 'קוד אימות צריך להיות בן 6 תווים',
	PHONE_MIN_LENGTH: 'טלפון צריך להיות בן 9 תווים לפחות',
	PHONE_MAX_LENGTH: 'טלפון צריך להיות בן 10 תווים לכל היותר',
	GENDER: 'מין',
	FULL_NAME: 'שם מלא',
	HAS_CREDIT: 'יש קרדיט',
	BUDGET: 'תקציב',
	TOTAL: 'סה״כ',
	CREATED_AT: 'נוצר בתאריך',
	FROM_CREATED_AT: 'שנוצרו מ-',
	BUSINESS_NAME: 'שם העסק',
	PREFERRED_CITIES: 'ערים מועדפות',
	TO_CREATED_AT: 'שנוצרו עד',
	ADDRESS_IS_REQUIRED: 'הכתובת הינה שדה חובה',
	CITY: 'עיר',
	CITIES: 'ערים',
	LOCATIONS_GROUP: 'קבוצת מיקומים',
	LOCATIONS_GROUPS: 'קבוצות מיקומים',
	LOCATION: 'לקוח',
	LOCATIONS: 'לקוחות',
	EMPLOYEE: 'עובד',
	EMPLOYEES: 'עובדים',
	DELIVERY_UPDATE: 'עדכון על קריאה',
	DELIVERY_UPDATES: 'עדכונים על קריאה',
	DELIVERY: 'קריאה',
	DELIVERIES: 'קריאות',
	COURIER: 'שליח',
	COURIERS: 'שליחים',
	Business: 'ספק',
	BUSINESS: 'ספק',
	BUSINESSES: 'ספקים',
	PRODUCT: 'מוצר',
	PRODUCTS: 'מוצרים',
	IMAGE: 'תמונה',
	DESCRIPTION: 'תיאור',
	Male: 'זכר',
	Female: 'נקבה',
	FULL_IMAGE: 'תמונה מלאה',
	SEND_NOTIFICATION_TO_CLIENTS: 'שליחת התראה לכל המשתמשים',
	NOTIFICATIONS: 'התראות',
	PICK_UP_REMARKS: 'הערות לאחר איסוף',
	DELIVERY_NOTES: 'הערות משלוח',
	TITLE: 'כותרת',
	ADDRESS: 'כתובת',
	DIRECTIONS: 'הוראות הגעה',
	PHONE: 'טלפון',
	EMAIL: 'מייל',
	CURRENT_NUMBER_OF_PACKAGES: 'מס׳ חבילות',
	CREATED_BY: 'נוצר ע״י',
	DELIVERY_PACKAGES: 'חבילות',
	STATUS: 'סטטוס',
	PICK_UP_ADDRESS: 'כתובת איסוף',
	DROP_OFF_ADDRESS: 'כתובת מסירה',
	AVAILABLE_DAYS: 'ימים פתוחים',
	FROM_TIME: 'פתוח משעה',
	TO_TIME: 'פתוח עד שעה',
	IS_URGENT: 'דחוף',
	TOKEN: 'טוקן',
	TYPE: 'סוג',
	USER: 'משתמש',
	IS_OWNER: 'בעלים',
	REMARKS: 'הערות',
	ADMINS: 'מנהלי מערכת',
	REPORTS: 'דו״חות',
	SHOW_LOCATIONS: 'צפייה בלקוחות',
	ADD_EMPLOYEE: 'הוספת עובד',
	TOTAL_DELIVERIES_DONE_TODAY: 'בוצעו היום',
	TOTAL_OPEN_DELIVERIES: 'פתוחות',
	TOTAL_COURIER_ASSIGNED_DELIVERIES: 'עם שליח',
	TOTAL_OPEN_URGENT_DELIVERIES: 'דחופות',
	COURIER_CHECKS: 'ציקים שנמצאים אצל השליח',
	APPROVE_AND_NULLIFY: 'אישור ואיפוס',
	COURIER_DELIVERIES_BUTTON: 'לצפייה בסידור העבודה',
	PICK_UP_CITY: 'עיר איסוף',
	CHECKS_DETAILS: 'צ׳קים',
	CASH_DETAILS: 'מזומן',
	SUM: 'סכום כולל',
	COUNT: 'כמות',
	MONTHS: 'חודשים',
	CHECKS_FOR_MONTHS: 'צ׳קים עבור חודשים',
	SIZE: 'גודל',
	Checks: 'צ׳קים',
	Cash: 'מזומן',
	Other: 'אחר',
	Small: 'קטן',
	Medium: 'בינוני',
	Big: 'גדול',
	New: 'שליחות פנויה',
	AssignToCourier: 'נתפס ע״י שליח',
	CREATE_NEW_DELIVERY_UPDATE: 'יצירת עדכון חדש',
	SAVE: 'שמירה',
	CANCEL: 'ביטול',
	Failed: 'איסוף נכשל',
	PickUp: 'איסוף בוצע',
	DropOff: 'הגיע לנמען',
	'DELIVERY_TYPE.PickUp': 'איסוף',
	Done: 'בוצע',
	Canceled: 'בוטל',
	'DELIVERY_TYPE.PickUpDropOff': 'איסוף ומסירה',
	PickUpDropOff: 'איסוף ומסירה',
	Motorbike: 'קטנוע',
	Car: 'רכב',
	Bicycle: 'אופניים',
	PICKED_UP_CHECKS: 'מס׳ צ׳קים שנאספו',
	PickedUp: 'נאסף',
	SEND_OTP: 'קבלת קוד אימות',
	VERIFY_OTP: 'התחברות',
	SHOW_LOCATIONS_GROUP: 'צפייה בלקוחות שמקושרים ללקוח זה',
	NO_LOCATIONS_GROUP: 'לקוח זה לא מקושר ללקוחות אחרים',
	GROUP_REF: 'קבוצת',
	LOGOUT: 'התנתקות',
	SEGMENTATION_BY_STATUS: 'פילוח לפי סטטוס',
	DELIVERY_UPDATE_CREATED_SUCCESSFULLY: 'עדכון נשלח בהצלחה',
	FAILED_CREATING_DELIVERY_UPDATE: 'שגיאה בשליחת עדכון',
	Approved: 'מאושר',
	Blocked: 'חסום',
	APPROVAL_STATUS: 'סטטוס אישור',
	SEGMENTATION_BY_TYPE: 'פילוח קריאות לפי סוג קריאה',
	SEGMENTATION_BY_FAIL_REASON: 'פילוח לפי סיבת כשל',
	SEE_FULL_REPORT: 'לצפייה בדו״ח המלא',
	SUNDAY_SHORT: 'א׳',
	MONDAY_SHORT: 'ב׳',
	TUESDAY_SHORT: 'ג׳',
	WEDNESDAY_SHORT: 'ד׳',
	THURSDAY_SHORT: 'ה׳',
	FRIDAY_SHORT: 'ו׳',
	SATURDAY_SHORT: 'ש׳',
	AVAILABILITY: 'שעות פעילות',
	PICK_UP_DIRECTIONS: 'הוראות הגעה לאיסוף',
	PICK_UP_DIRECTIONS_IMAGES: 'הכוונה לאיסוף',
	DROP_OFF_DIRECTIONS: 'הוראות הגעה למסירה',
	DROP_OFF_DIRECTIONS_IMAGES: 'הכוונה למסירה',
	DETAILS: 'פרטים',
	UPDATES: 'עדכונים',
	DIRECTIONS_IMAGES: 'הכוונה להגעה',
	CONTACT_NAME: 'שם ליצירת קשר',
	CONTACT_NAME_B: 'שם נוסף ליצירת קשר',
	CONTACT_PHONE: 'טלפון ליצירת קשר',
	CONTACT_PHONE_B: 'טלפון נוסף ליצירת קשר',
	CONTACT_EMAIL: 'מייל ליצירת קשר',
	USER_TYPE: 'סוג משתמש',
	Admin: 'מנהל מערכת',
	SubAdmin: 'עובד אניספוט',
	Employee: 'עובדי ספק',
	Courier: 'שליח',
	CONTACT_EMAIL_B: 'מייל נוסף ליצירת קשר',
	IS_LEGAL: 'מסירה משפטית?',
	IS_LEGAL_LABEL: 'משפטי',
	IS_URGENT_LABEL: 'דחוף',
	FILES: 'מסמכים',
	DELIVERY_PRICE: 'מחיר לאיסוף',
	CLOSED_DELIVERY_FIELDS: 'פרטי משלוח סגור',
	WAITING_TIME: 'זמן המתנה',
	FAIL_REASON: 'סיבת כישלון',
	NUMBER_OF_CHECKS: 'מספר צ׳קים',
	RELATED_DELIVERY: 'משלוח משוייך',
	SomeoneElsePicked: 'נאסף ע״י שליח אחר',
	WrongAddress: 'כתובת שגויה',
	ContactNotAvailable: 'איש קשר לא זמין',
	ClosedLocation: 'מקום סגור',
	CheckNotReady: 'צ׳ק לא מוכן',
	deliveries_EXISTS: 'משלוח קיים',
	deliveries_EXISTS_DESCRIPTION: 'מצאנו קריאה פתוחה עבור לקוח זה',
	SHOW_ME: 'הצג לי',
	OVERRIDE_CREATION: 'צור בכל זאת',
	CIRCUIT_DRIVER_ID: 'מזהה Circuit',
	DELIVERY_DATE: 'תאריך איסוף',
	LAST_STATUS_UPDATE_DATE: 'סטטוס התעדכן לאחרונה ב-',
	FROM_LAST_STATUS_UPDATE_DATE: 'מתאריך עדכון סטטוס אחרון',
	TO_LAST_STATUS_UPDATE_DATE: 'עד תאריך עדכון סטטוס אחרון',
	VERIFICATIONS: 'קודי אימות',
	VERIFICATION: 'אימות',
	VERIFICATION_CODE: 'קוד אימות',
	WORKING_RADIUS: 'רדיוס עבודה בק״מ',
	CLEAR_FILTERS: 'איפוס סינונים',
	LOCATION_CONTACT_DETAILS: 'פרטי התקשרות עם לקוח',
	REFRESH: 'ריענון',
	MINIMUM_ONE_PACKAGE: 'לפחות חבילה אחת',
	COURIER_PHONE: 'טלפון שליח',
	NOTIFICATION_LABEL: 'סוג התראה',
	DUPLICATED_BY_COURIER: 'שופכל ע״י שליח',
	CREATED_BY_COURIER: 'נוצר ע״י שליח',
	SEGMENTATION_BY_CREATION_WAY: 'פילוח ע״פי דרך יצירה',
	REGULARLY_CREATED: 'נוצר רגיל',
	DUPLICATED: 'שוכפל',
	CREATED_BY_ADMINS: 'נוצרו ע״י מנהלי מערכת',
	UNKNOWN_CREATOR: 'לא ידוע',
	DELIVERY_TYPE_PICKUP: "איסוף בלבד",
	DELIVERY_TYPE_PICKUP_WITH_RETURN: "איסוף ומסירה",
	NOTIFICATION_LABELS: {
		New: 'חדש',
		Done: 'בוצע',
		Update: 'עודכן',
		Attached: 'הוצמד',
		Urgent: 'דחוף',
		Deleted: 'נמחק',
		Failed:  'נכשל'
	},
	deliveries: {
		create: {
			exists: 'מצאנו קריאה פתוחה עבור לקוח זה',
		}
	},
	general: {
		error: 'שגיאה כללית'
	},
	message: {
		error: 'שגיאה כללית'
	},
	error: {
		general: 'שגיאה כללית',
		request: {
			invalid_req_params: 'נשלחו פרטים לא תקינים'
		},
		files: {
			empty: 'לא נשלח קובץ',
			unsupported_ext: 'סיומת קובץ לא נתמכת'
		},
		auth: {
			wrong_credentials: 'פרטי הזדהות לא תקינים',
			not_admin: 'אין הרשאות אדמין',
			not_client: 'אין הרשאות לקוח',
			failed_authenticating_jwt: 'משתמש לא מזוהה',
			not_registered: 'משתמש לא קיים',
			invalid_token: 'משתמש פג תוקף'
		}
	},
	resources: {
		'city': { name: 'עיר', },
		'cities': { name: 'ערים', },

		'locations-group': { name: 'קבוצת מיקומים', },
		'locations-groups': { name: 'קבוצות מיקומים', },

		'location': { name: 'לקוח', },
		'locations': { name: 'לקוחות', },

		'employee': { name: 'עובד', },
		'employees': { name: 'עובדים', },

		'delivery-update': { name: 'עדכון על קריאה', },
		'delivery-updates': { name: 'עדכונים על קריאה', },

		'delivery': { name: 'קריאה', },
		'deliveries': {
			name: 'קריאות',
			fields: {
				availableDays: 'ימים פתוחים',
			}
		},

		'courier': { name: 'שליח', },
		'couriers': { name: 'שליחים', },

		'business': { name: 'ספק', },
		'businesses': { name: 'ספקים', },

		'product': { name: 'מוצר' },
		'products': { name: 'מוצרים' },
	},
	ra: {
		configurable: {
			customize: 'הערה מוזרה'
		},
		saved_queries: {
			label: 'חיפושים שמורים',
			query_name: 'כותרת חיפוש',
			new_label: 'שמירת חיפוש',
			new_dialog_title: 'שמירת חיפוש בתור',
			remove_label: 'מחיקת חיפוש',
			remove_label_with_name: 'מחיקת חיפוש "%{name}"',
			remove_dialog_title: 'למחוק את החיפוש?',
			remove_message:
				'האם אתה בטוח שברצונך למחוק חיפוש מרשימת החיפושים?',
			help: 'חפש ושמור את החיפוש לפעם הבאה',
		},
		action: {
			clear_input_value: 'נקה',
			clear_array_input: 'הסרת פריט',
			delete: 'מחק',
			unselect: 'בטל בחירה',
			sort: 'מיון',
			show: 'הצג',
			undo: 'שחזר',
			confirm: 'אישור',
			bulk_actions: 'נבחרו %{smart_count} פריטים',
			list: 'רשימה',
			export: 'ייצוא',
			save: 'שמור',
			create: 'יצירה',
			edit: 'ערוך',
			cancel: 'ביטול',
			refresh: 'רענן',
			remove: 'הסרה',
			add: 'הוספה',
			add_filter: 'הוסף סינון',
			remove_filter: 'הסר סינון',
			remove_all_filters: 'הסר סינונים',
			back: 'חזור'
		},
		boolean: {
			true: 'כן',
			false: 'לא'
		},
		page: {
			loading: 'טוען..',
			list: 'רשימת %{name}',
			edit: '%{name} #%{id}',
			show: '%{name} #%{id}',
			empty: 'לא נמצאו תוצאות',
			invite: 'מקווים שממש בקרוב יגיע לפה מידע...',
			create: 'צור %{name}',
			delete: 'מחק %{name} #%{id}',
			dashboard: 'דשבורד',
			not_found: 'לא קיים',
			error: 'שגיאה'
		},
		input: {
			references: {
				single_missing: 'חסר'
			},
			file: {
				upload_several:
					'גרור מספר קבצים להעלאה או לחץ לבחור אחד.',
				upload_single: 'גרור קובץ להעלאה או לחץ לבחור אחד.'
			},
			image: {
				upload_several:
					'גרור מספר תמונות להעלאה או לחץ לבחור אותן.',
				upload_single:
					'גרור תמונה להעלאה או לחץ לבחור אחת.'
			}
		},
		message: {
			loading: 'כבר מסיימים לטעון את הנתונים',
			clear_array_input: 'האם להסיר את הפריט?',
			error: 'אופס.. קרתה שגיאה',
			invalid_form: 'טופס לא תקין',
			yes: 'כן',
			no: 'לא',
			are_you_sure: 'האם את/ה בטוח/ה ?',
			about: 'אודות',
			not_found:
				'הכנסת כתובת שגויה או לחצת על קישור לא תקין',
			delete_title: 'מחיקה',
			delete_content: 'האם אתה בטוח שברצונך למחוק?',
			bulk_delete_title: 'שים לב!',
			bulk_delete_content: 'האם אתה בטוח שברצונך למחוק את כל המידע הזה?'
		},
		navigation: {
			no_results: 'לא נמצאו תוצאות',
			page_rows_per_page: 'מס׳ שורות בעמוד',
			skip_nav: 'דילוג',
			page_out_of_boundaries: 'עמוד %{page} מחוץ לתחום',
			page_out_from_end: 'לא ניתן להמשיך מעבר לעמוד האחרון',
			page_out_from_begin: 'לא ניתן לגשת לפני העמוד הראשון',
			page_range_info: '%{offsetBegin}-%{offsetEnd} מתוך %{total}',
			next: 'הבא',
			prev: 'הקודם'
		},
		auth: {
			username: 'שם משתמש',
			password: 'סיסמא',
			sign_in: 'כניסה',
			sign_in_error: 'הכניסה נכשלה, אנא נסו שנית',
			logout: 'התנתקות',
			auth_check_error: 'הזדהות לא הצליחה'
		},
		LAST_STATUS_UPDATE_DATE: 'סטטוס התעדכן לאחרונה ב-',
		notification: {
			data_provider_error: 'תקלה בתקשורת מידע',
			LAST_STATUS_UPDATE_DATE: 'סטטוס התעדכן לאחרונה ב-',
			updated: 'פריט עודכן בהצלחה',
			created: 'פריט נוצר בהצלחה',
			deleted: 'פריט נמחק בהצלחה',
			item_doesnt_exist: 'פריט לא קיים',
			http_error: 'שגיאה בהתקשרות מול השרת'
		},
		validation: {
			required: 'שדה חובה',
			minLength: 'חייב להכיל לפחות %{min} תווים',
			maxLength: 'יכול להכיל לכל היותר %{max} תווים',
			minValue: 'חייב להיות לפחות %{min}',
			maxValue: 'חייב להיות %{max} או פחות',
			number: 'חייב להיות מספר',
			email: 'כתובת דוא"ל לא תקנית'
		}
	}
};
