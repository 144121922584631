import * as React from 'react';
import {
	ArrayField,
	BooleanField,
	Datagrid,
	DateField,
	FunctionField,
	ReferenceField,
	SingleFieldList,
	TextField,
	usePermissions,
	useTranslate
} from 'react-admin';
import { TranslateField } from '../components/TranslateField';
import './DeliveryDatagrid.scss';
import WeekdaysField from '../components/WeekdaysField';
import { EUserType } from '../enums';
import _get from 'lodash-es/get';
import { PhoneField } from '../components/PhoneField';

export const DeliveryDatagrid = (): React.ReactElement => {
	const translator = useTranslate();
	const { permissions } = usePermissions();

	return (
		<Datagrid rowClick="show" className="deliveries-table" optimized bulkActionButtons={false}
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<DateField locales="he-IL" source="_createdAt" label="CREATED_AT" showTime/>
			<ReferenceField source="courierId" reference="couriers" label="COURIER"
			                sortable={false}
			                link={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? 'edit' : false}>
				<TextField source="user.fullName"/>
			</ReferenceField>
			{!([EUserType.Admin, EUserType.SubAdmin].includes(permissions)) && <ReferenceField source="courierId" reference="couriers" label="COURIER_PHONE"
			                sortable={false}
			                link={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? 'edit' : false}>
				<PhoneField source="user.phone"/>
			</ReferenceField>}
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <ReferenceField source="businessId" reference="businesses" label="BUSINESS" sortable={false}>
                    <TextField source="name"/>
                </ReferenceField>}
			<ReferenceField source="createdById" reference="users" label="CREATED_BY" sortable={false}>
				<TextField source="fullName"/>
			</ReferenceField>
			<ReferenceField source="locationId" reference="locations" label="LOCATION" sortable={false}>
				<TextField source="name"/>
			</ReferenceField>
			<ArrayField source="deliveryPackages" label="DELIVERY_PACKAGES" sortable={false}>
				<SingleFieldList linkType={false}>
					<FunctionField
						render={(record, source) => `${translator(record.type)} [${translator(record.size)}]`}
					/>
				</SingleFieldList>
			</ArrayField>
			{[EUserType.Admin].includes(permissions) &&
                <TextField source="price" label="PRICE"/>
			}
			<TranslateField source="status" label="STATUS"/>
			<DateField locales="he-IL" source="lastStatusUpdateDate" label="LAST_STATUS_UPDATE_DATE" showTime/>
			<TextField source="pickUpAddress.addressStr" label="PICK_UP_ADDRESS" sortable={false}/>
			<ReferenceField source="pickUpCityId" reference="cities" label="PICK_UP_CITY"
			                sortable={false}
			                link={[EUserType.Admin, EUserType.SubAdmin].includes(permissions) ? 'edit' : false}>
				<TextField source="name"/>
			</ReferenceField>
			<TextField source="dropOffAddress.addressStr" label="DROP_OFF_ADDRESS" sortable={false}/>
			<DateField locales="he-IL" source="date" label="DATE"/>
			<WeekdaysField source="availableDays"/>
			<TextField source="fromTime" label="FROM_TIME"/>
			<TextField source="toTime" label="TO_TIME"/>
			<BooleanField source="isUrgent" label="IS_URGENT"/>
			{/*<TextField source="token" label="TOKEN"/>*/}
			<FunctionField source="type" label="TYPE"
			               sortable={false}
			               render={(record, source) => translator(`DELIVERY_TYPE.${_get(record, source) || ''}`)}
			/>
		</Datagrid>
	);
};
