import React, { useState } from 'react';
import {
	maxLength,
	minLength,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	useLogin,
	useNotify,
	useTranslate
} from 'react-admin';
import { Card } from '@mui/material';
import { httpClient } from '../providers/http_client';
import { API_URL } from '../providers/constants';

const styles = {
	loginForm: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		'& form': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: '2rem'
		}
	},
};

export const OtpForm = () => {
	const [phone, setPhone] = useState('');
	const [verificationCode, setVerificationCode] = useState('');
	const [step, setStep] = useState(1);
	const login = useLogin();
	const notify = useNotify();
	const translate = useTranslate();
	const classes = styles;

	const handleSendOtp = async () => {
		try {
			await httpClient(`${API_URL}/auth/register`, {
				method: 'POST',
				body: JSON.stringify({
					phone: { phone, countryCode: 'IL', prefix: '972' },
				}),
			});

			setStep(2);
			setTimeout(() => {
				setVerificationCode('');
			}, 3000);
			notify(translate('OTP sent successfully'));
		} catch (error) {
			notify(translate('Error sending OTP'));
		}
	};

	const handleLogin = async (event) => {
		try {
			await login({ phone, verificationCode });
		} catch (error) {
			notify(translate('Invalid OTP'));
		}
	};

	return (
		<Card sx={classes.loginForm}>
			<SimpleForm onSubmit={(event) => step === 1 ? handleSendOtp() : handleLogin(event)} toolbar={false}>
				{step === 1 && <TextInput
                    dir="ltr"
                    sx={{ direction: 'ltr !important' }}
                    name="phone"
                    label={translate('PHONE_NUMBER')}
                    source="phone"
                    value={phone}
                    validate={[required(), minLength(9, translate('PHONE_MIN_LENGTH')), maxLength(10, translate('PHONE_MAX_LENGTH'))]}
                    onChange={(e) => setPhone(e.target.value)}
                />}
				{step === 2 && <TextInput
                    name="verificationCode"
                    label={translate('OTP')}
                    source="verificationCode"
                    value={verificationCode}
                    validate={[required(), maxLength(6, translate('OTP_LENGTH_ERROR'))]}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />}
				<SaveButton label={step === 1 ? translate('SEND_OTP') : translate('VERIFY_OTP')}>
				</SaveButton>
			</SimpleForm>
		</Card>
	);
};

export default OtpForm;
