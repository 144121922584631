export const isDateOrDateString = (variable) => {
	if (variable instanceof Date) {
		return true;
	} else if (typeof variable === 'string') {
		const date = new Date(variable);
		return !isNaN(date as any);
	}
	return false;
};

export const formatPhoneNumber = (value) => {
	if (!value) return value;
	let newValue = value.replace(/[^\d]/g, '');
	if (newValue.startsWith('972')) {
		newValue = newValue.replace('972', '0');
	}

	return newValue;
};
