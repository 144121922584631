import * as React from 'react';
import { BooleanField, Datagrid, EmailField, ReferenceField, TextField, usePermissions } from 'react-admin';
import { PhoneField } from '../components/PhoneField';
import './EmployeeDatagrid.scss';
import { EUserType } from '../enums';
import { TranslateField } from '../components/TranslateField';
import { LightboxImageField } from '../components/LightboxImageField';
import { LOCAL_STORAGE_ME_DETAILS } from '../providers/constants';
import { Employee } from './Employee';

export const EmployeeDatagrid = (): React.ReactElement => {
	const { permissions } = usePermissions();
	const storedUserData = localStorage.getItem(LOCAL_STORAGE_ME_DETAILS);
	let isNotOwner = false;

	if (storedUserData) {
		const userData = JSON.parse(storedUserData);

		if ((userData?.employee as Employee)?.isOwner === false) {
			isNotOwner = true;
		}
	}

	return (
		<Datagrid rowClick={isNotOwner ? 'show' : 'edit'} className="employees-table" optimized
		          rowStyle={(row: any) => ({ borderRightColor: row._readAt ? '#49d18c' : '#bbbbbb' })}>
			<TextField source="id" label="#"/>
			<LightboxImageField source="profileImageUrl" label="PROFILE_IMAGE" sortable={false}/>
			{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                <ReferenceField source="businessId" reference="businesses" label="BUSINESS" sortable={false}>
                    <TextField source="name"/>
                </ReferenceField>
			}
			<TextField source="user.fullName" label="NAME" sortable={false}/>
			<PhoneField source="user.phone.phone" label="PHONE" sortable={false}/>
			<EmailField source="user.email" label="EMAIL" sortable={false}/>
			<BooleanField source="isOwner" label="IS_OWNER"/>
			<TranslateField source="user.approvalStatus" label="APPROVAL_STATUS"/>
		</Datagrid>
	);
};
