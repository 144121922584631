import { EPlatform } from '../enums';
import { fetchUtils } from 'react-admin';
import { LOCAL_STORAGE_ME_DETAILS } from './constants';

const awesomePromise = async <T>(promise: Promise<T>): Promise<[T, Error & any | null]> => {
	try {
		return [await promise, null];
	} catch (e) {
		return [null as unknown as T, e];
	}
};

export const httpClient = async (url: string, options: { headers?: Headers, method?: string, body?: any } = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}

	const me = localStorage.getItem(LOCAL_STORAGE_ME_DETAILS);

	if (me) {
		const { jwt } = JSON.parse(me);
		options.headers.set('Authorization', `JWT ${jwt}`);
	}

	options.headers.set('Platform', EPlatform.AdminPanel);

	const [data, error] = await awesomePromise(fetchUtils.fetchJson(url, options));

	if (error) {
		console.log(error);
		throw new Error(error.body?.errors[0]?.code);
	}

	return data;
};
