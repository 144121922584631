import * as React from 'react';
import { Courier } from './Courier';
import {
	ChipField,
	Loading,
	ReferenceArrayField,
	SingleFieldList,
	useRecordContext,
	useTranslate,
	useUpdate
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SpokeIcon from '@mui/icons-material/Spoke';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './CourierProfile.scss';
import { LightboxImageField } from '../components/LightboxImageField';

const styles = {
	ctaIcon: {
		boxShadow: '1px 1px 7px #0000003d'
	}
};

export const CourierProfile = () => {
	const courier: Courier = useRecordContext();
	const translator = useTranslate();
	const navigate = useNavigate();
	const [update] = useUpdate('couriers', { id: courier?.id, data: { currentNumberOfPackages: 0 } });

	if (courier)
		return <div className="courier-profile">
			<div className="courier-profile-image">
				<LightboxImageField source="profileImageUrl" record={courier}
				                    alt={translator('EMPLOYEE')}/>
			</div>
			<div className="courier-profile-name">
				<span>{courier.user?.fullName}</span>
			</div>
			<div className="courier-profile-contact">
				<div className="courier-profile-contact-detail">
					<div className="courier-profile-contact-detail-icon"><EmailIcon/></div>
					<div className="courier-profile-contact-detail-content">{courier.user?.email}</div>
					<div className="courier-profile-contact-detail-cta">
						<IconButton sx={styles.ctaIcon} onClick={() => {
							const textField = document.createElement('textarea');
							textField.innerText = courier.user?.email;
							document.body.appendChild(textField);
							textField.select();
							document.execCommand('copy');
							textField.remove();
						}}><CopyAllIcon/></IconButton>
					</div>
				</div>
				<div className="courier-profile-contact-detail">
					<div className="courier-profile-contact-detail-icon"><PhoneIcon/></div>
					<div className="courier-profile-contact-detail-content">{courier.user?.phone?.phone}</div>
					<div className="courier-profile-contact-detail-cta">
						<Link target="_blank"
						      href={`https://wa.me/${courier.user?.phone.prefix}${courier.user?.phone.phone}`}><IconButton
							sx={styles.ctaIcon}><WhatsAppIcon/></IconButton></Link>
						<Link target="_blank" href={`tel:${courier.user?.phone.phone}`}><IconButton sx={styles.ctaIcon}><PhoneIcon/></IconButton></Link>
					</div>
				</div>
				{courier.circuitDriverId && <div className="courier-profile-contact-detail">
                    <div className="courier-profile-contact-detail-icon"><IntegrationInstructionsIcon/></div>
                    <div className="courier-profile-contact-detail-content">{courier.circuitDriverId}</div>
                </div>}
				{courier.workingRadius && <div className="courier-profile-contact-detail">
                    <div className="courier-profile-contact-detail-icon"><SpokeIcon/></div>
                    <div className="courier-profile-contact-detail-content">{courier.workingRadius} ק״מ</div>
                </div>}
				{courier.preferredCitiesIds?.length > 0 && <div className="courier-profile-contact-detail">
                    <div className="courier-profile-contact-detail-icon"><LocationCityIcon/></div>
                    <div className="courier-profile-contact-detail-content">
                        <ReferenceArrayField reference="cities" source="preferredCitiesIds" record={courier}>
                            <SingleFieldList>
                                <ChipField source="name"/>
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </div>
                </div>}
			</div>
			<div className="courier-profile-ctas">
				<div className="courier-profile-ctas-clear-packages">
					<span>{`${translator('COURIER_CHECKS')} (${courier.currentNumberOfPackages ?? 0})`}</span>
					<Button variant="contained"
					        onClick={() => update()}><span>{translator('APPROVE_AND_NULLIFY')}</span></Button>
				</div>
				<Button variant="outlined"
				        onClick={() => navigate(`/deliveries?${encodeURI(`displayedFilters={"courier":true, "in_status":true}&filter={"courier":${courier.id}, "in_status":["AssignToCourier"]}`)}`, {})}><span>{translator('COURIER_DELIVERIES_BUTTON')}</span></Button>
			</div>
		</div>;

	return <Loading/>;
};
