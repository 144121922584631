import * as React from 'react';
import { EditButton, ReferenceField, ShowButton, TextField, useListContext, usePermissions } from 'react-admin';
import { Box, Card, CardHeader, CardActions } from '@mui/material';
import './LocationList.scss';
import { EUserType } from "../enums";

const locationStyle = {
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
		boxShadow: '0px 15px 20px #27313f0c !important',
		backgroundColor: 'white'
	},
	cardTitleContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	cardContent: {},
	cardContentRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '0.5rem 0',
		fontWeight: 'bold'
	}
};

export const LocationList = () => {
	const classes = locationStyle;
	const { permissions } = usePermissions();
	const { data } = useListContext({});

	if (!data) {
		return null;
	}

	return (
		<div style={{ margin: '1em', backgroundColor: '#F8F9FC' }} className="list-container">
			{data.map(entity => (
				<Card key={entity.id} sx={classes.card}>
					<CardHeader
						title={
							<Box sx={classes.cardTitleContent}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span>{entity.name}</span>
									<span style={{ fontSize: '.9rem' }}>{entity.address?.addressStr}</span>
									{[EUserType.Admin, EUserType.SubAdmin].includes(permissions) &&
                                        <ReferenceField label="Business" source="businessId" reference="businesses"
                                                        record={entity}>
                                            <TextField source="name"/>
                                        </ReferenceField>
									}
								</div>
							</Box>
						}
					/>
					<CardActions>
						<div style={{ display: 'flex' }}>
							<ShowButton
								resource="locations"
								record={entity}
							/>
							<EditButton
								resource="locations"
								record={entity}
							/>
						</div>
					</CardActions>
				</Card>
			))}
		</div>
	);
};
