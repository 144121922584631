import {
	CreateParams,
	DataProvider,
	DeleteManyParams,
	DeleteParams,
	GetListParams,
	GetManyParams,
	GetManyReferenceParams,
	GetOneParams,
	LegacyDataProvider,
	UpdateManyParams,
	UpdateParams,
	UseDataProviderOptions
} from 'react-admin';
import { stringify } from 'query-string';
import { serialize } from '../util/Serialize';
import { formatQueryParams } from '../util/Formatters';
import { httpClient } from './http_client';
import { API_URL, LOCAL_STORAGE_BUSINESS_ID, LOCAL_STORAGE_USER_TYPE } from './constants';
import { EUserType } from '../enums';

const extractUserBasedUrlPrefix = () => {
	const userType = localStorage.getItem(LOCAL_STORAGE_USER_TYPE);

	if (userType === EUserType.Employee) {
		const businessId = localStorage.getItem(LOCAL_STORAGE_BUSINESS_ID);
		return `${API_URL}/admin/businesses/${businessId}`;
	} else {
		return `${API_URL}/admin`;
	}
};

const GatewayProvider: DataProvider | LegacyDataProvider = {
	getList: (resource: string, params: GetListParams) => {
		const properties = formatQueryParams(params);
		const routePrefix = extractUserBasedUrlPrefix();

		const url = `${routePrefix}/${resource}?${serialize(properties)}`;

		return httpClient(url).then(({ headers, json }) => {
			return {
				data: json.data.entities,
				total: json.data.total
			};
		});
	},

	getOne: (resource: string, params: GetOneParams, options?: UseDataProviderOptions) => {
		const routePrefix = extractUserBasedUrlPrefix();

		return httpClient(`${routePrefix}/${resource}/${params.id}`).then(({ json }) => ({
			data: json.data
		}));
	},

	getMany: (resource: string, params: GetManyParams, options?: UseDataProviderOptions) => {
		const ids: string[] = params.ids?.length ? ((params.ids[0] as any)?.id ? params.ids.map((e: any) => e.id) : params.ids) : [];
		const routePrefix = extractUserBasedUrlPrefix();

		const url = `${routePrefix}/${resource}?ids=[${ids.join(',')}]`;

		return httpClient(url).then(({ headers, json }) => {
			return {
				data: json.data.entities,
				total: json.data.total
			};
		});
	},

	getManyReference: (resource: string, params: GetManyReferenceParams, options?: UseDataProviderOptions) => {
		const properties = formatQueryParams({
			...params,
			filter: { ...params.filter ?? {}, [params.target]: params.id }
		});
		const routePrefix = extractUserBasedUrlPrefix();

		const url = `${routePrefix}/${resource}?${serialize(properties)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json.data?.entities,
			total: json.data?.total ?? parseInt(headers.get('content-range')?.split('/')?.pop() ?? '0', 10)
		}));
	},

	update: (resource: string, params: UpdateParams, options?: UseDataProviderOptions) => {
		const routePrefix = extractUserBasedUrlPrefix();

		return httpClient(`${routePrefix}/${resource}/${params.id}`, {
			method: 'PUT',
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json.data }));
	},

	updateMany: (resource: string, params: UpdateManyParams) => {
		const query = {
			filter: JSON.stringify({ id: params.data.ids })
		};
		const routePrefix = extractUserBasedUrlPrefix();

		return httpClient(`${routePrefix}/${resource}?${stringify(query)}`, {
			method: 'PUT',
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json.data }));
	},

	create: (resource: string, params: CreateParams) => {
		const routePrefix = extractUserBasedUrlPrefix();

		return httpClient(`${routePrefix}/${resource}`, {
			method: 'POST',
			body: JSON.stringify(params.data)
		}).then(({ json }) => ({ data: json.data }));
	},

	delete: (resource: string, params: DeleteParams) => {
		const routePrefix = extractUserBasedUrlPrefix();

		return httpClient(`${routePrefix}/${resource}/${params.id}`, {
			method: 'DELETE'
		}).then(({ json }) => ({ data: json.data }));
	},

	deleteMany: (resource: string, params: DeleteManyParams) => {
		const routePrefix = extractUserBasedUrlPrefix();

		return httpClient(`${routePrefix}/${resource}?${stringify(params)}`, {
			method: 'DELETE'
		}).then(({ json }) => ({ data: json.data }));
	}
};

export default GatewayProvider;
